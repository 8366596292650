
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data() {
    var self = this;
    return {
      swipeout: self.$app.data.permiteVisualizarCusto !== undefined ? 'swipeout' : '',
      loading: true,
      hack_data: new Date(),
      escondeuNavbar: false,
      indiceScroll: self.$app.data.indiceScrollPesquisar === undefined ? 20 : self.$app.data.indiceScrollPesquisar,
      mostrar_sacola: '',
      visao_grade: '-50',
      typingTimer: undefined,
      doneTypingInterval: 500,
      ultimatela: '',
      referencia_esgotada: '',
      abrir_produtos: false,
      marca_atual: 'Todas',
      categoria_atual: 'Todas',
      tamanho_atual: 'Todos',
      totalcatalogos: self.$app.data.catalogos.length - 1,
      numedicao: self.$app.data.catalogos[0],
      numedicaotravada: self.$app.data.catalogos[0],
      produtos_canonicos: [],
      mostracarregamento: 'none',
      posicaoedicao: 0,
      produtos_pesquisados: [],
      catalogos: [...self.$app.data.catalogos],
      travareload: false
    };
  },
  on: {
    pageMounted() {
      var self = this, historico = self.$app.view.main.history;
      for (var i = historico.length - 1; i >= 0; i--) {
        if (self.ultimatela === '') {
          if (historico[i].indexOf('home') >= 0) {
            self.ultimatela = historico[i];
            break;
          } else if (historico[i].indexOf('grades') >= 0) {
            self.ultimatela = historico[i];
            break;
          }
        }
      }
      self.$app.data.referencia_esgotada = '';
      self.$app.preloader.show();
      self.getProdutos(self.numedicaotravada);
      self.setPesquisar();
      self.setBadgeSacola();
    }
  },
  methods: {
    abrirFiltros() {
      var self = this;
      self.$app.panel.open('right');
    },
    getPedidos() {
      var self = this;
      self.$root.getPedidosHoje(true).then(function (pedidos) {
        self.renderizaListaPedidos(pedidos);
      });
    },
    renderizaListaPedidos(pedidos) {
      var self = this;
      var status = 'Aguardando', cor = 'purple';
      var array_pedidos = pedidos.filter(function (pedido) {
        return pedido.arrayCatalogoItensObjeto.find(function (item) {
          return item.descricao.indexOf('Catalogo') >= 0;
        });
      }).map(function (pedido) {
        if (pedido.tipoestoque === 'catalogo') {
          if (pedido.excluido === false && pedido.dataaprovado === 'null' && pedido.datafaturado === 'null') {
            status = 'Aguardando';
            cor = 'purple';
          } else if (pedido.excluido === true && pedido.dataaprovado !== 'null' && pedido.datafaturado === 'null') {
            status = 'Rejeitado';
            cor = 'red';
          } else if (pedido.excluido === false && pedido.dataaprovado !== 'null' && pedido.datafaturado === 'null') {
            status = 'Aprovado';
            cor = 'green';
          } else if (pedido.excluido === false && pedido.datafaturado !== 'null') {
            if (pedido.tipo === 'faturadofabrica') {
              status = 'Faturado Fábrica';
              cor = 'blue';
            } else if (pedido.tipo === 'importado') {
              status = 'Importado';
              cor = 'yellow';
            } else if (pedido.tipo === 'faturadocliente') {
              status = 'Faturado Cliente';
              cor = 'lime';
            }
          }
        } else if (pedido.tipoestoque === 'distribuidor') {
          if (pedido.excluido === false && pedido.tipo === 'agmobile') {
            status = 'Aguardando (Fiorani)';
            cor = 'purple';
          } else if (pedido.excluido === true && pedido.tipo === 'agmobile') {
            status = 'Rejeitado (Fiorani)';
            cor = 'red';
          } else if (pedido.excluido === false && pedido.tipo === 'pendente') {
            status = 'Aprovado (Fiorani)';
            cor = 'blue';
          }
        }
        return {
          datapedido: self.$root.getData(pedido.data),
          timestamp: self.$root.getDataTimestamp(pedido.data),
          numpedido: pedido.numpedido,
          idcatalogo: pedido.idcatalogo,
          valortotal: 'R$ ' + (pedido.valortotal > 0 ? self.$root.formataMoeda(pedido.valortotal) : self.$root.formataMoeda(pedido.arrayCatalogoItensObjeto.map(function (item) {
            return item.valortotal;
          }).reduce(function (a, d) {
            return a + d;
          }))),
          itens: pedido.arrayCatalogoItensObjeto.map(function (item) {
            return {
              descricao: item.descricao,
              idproduto: item.idproduto,
              idprodutograde: item.idprodutograde,
              idprodutosubsecao: item.idprodutosubsecao,
              quantidade: item.quantidade,
              valortotal: item.valortotal
            };
          }),
          status: status,
          cor: cor
        };
      });
      self.$setState({ pedidos: array_pedidos });
    },
    abrirItens(idcatalogo) {
      var self = this;
      self.$setState({
        itens: self.pedidos.find(function (pedido) {
          return pedido.idcatalogo === idcatalogo;
        }).itens.map(function (item) {
          var dados = self.$app.data.estoquegeral.find(function (e) {
            return e.idproduto === item.idproduto && e.idprodutograde === item.idprodutograde && e.idprodutosubsecao === item.idprodutosubsecao;
          });
          var array_descricao = item.descricao.split(' ');
          return {
            referencia: dados !== undefined ? dados.ctrl_conjunto : array_descricao.find(function (e) {
              return !isNaN(e);
            }),
            tamanho: dados !== undefined ? dados.tamanho : array_descricao.find(function (e) {
              return self.$app.data.array_tamanhos.map(function (d) {
                return d.tamanho;
              }).indexOf(e) >= 0;
            }),
            valortotalBR: 'R$ ' + self.$root.formataMoeda(item.valortotal),
            quantidade: item.quantidade,
            descricao: item.descricao
          };
        }).filter(function (e) {
          return e.quantidade > 0;
        })
      });
    },
    setFecharSheet() {
      var self = this;
      self.$app.toast.close();
      self.$app.sheet.close();
      if (self.abrir_produtos === true) {
        self.$app.popover.open('.popup-produto', false);
      }
    },
    setFecharItensPedido() {
      var self = this;
      self.$app.sheet.close();
      self.$app.popover.open('.popup-pedidos', false);
      document.querySelector('.popup-backdrop') !== null ? document.querySelector('.popup-backdrop').remove() : null;
      document.querySelector('.popover-backdrop') !== null ? document.querySelector('.popover-backdrop').remove() : null;
    },
    setBadgeSacola() {
      var self = this, total_sacola = self.$root.getQuantidadeTotalSacola();
      self.$setState({
        mostrar_sacola: total_sacola > 0 ? 'sim' : '',
        total_sacola: total_sacola
      });
    },
    setReabrePopupProduto() {
      var self = this;
      self.$app.popup.close();
      self.$app.popover.open('.popup-produto', false);
    },
    setAbrirPopupTamanho() {
      var self = this;
      self.$app.popup.close();
      self.$app.popover.open('.popup-tamanho', false);
      if (self.swiperTamanhos !== undefined) {
        self.swiperTamanhos.destroy();
      }
      self.swiperTamanhos = new self.$app.swiper.create('.swiper-fotos-tamanhos', {
        navigation: {
          nextEl: '.swiper-button-next-tamanhos',
          prevEl: '.swiper-button-prev-tamanhos'
        },
        zoom: { maxRatio: 3 }
      });
      document.querySelector('.popup-backdrop') !== null ? document.querySelector('.popup-backdrop').remove() : null;
      document.querySelector('.popover-backdrop') !== null ? document.querySelector('.popover-backdrop').remove() : null;
    },
    setBackdrop() {
      var self = this;
    },
    iniSteppers() {
      var self = this;
      self.linhas.forEach(function (linha) {
        var el = '.stepper-grade-' + linha.idprodutograde;
        self['stepper-grade-' + linha.idprodutograde] = self.$app.stepper.get(el);
        self.$app.stepper.get(el).setValue(linha.quantidade);
      });
    },
    iniSwiperFotos() {
      var self = this;
      if (self.swiperFotos !== undefined) {
        self.swiperFotos.destroy();
      }
      self.swiperFotos = new self.$app.swiper.create('.swiper-fotos-grade', {
        navigation: {
          nextEl: '.swiper-button-next-grade',
          prevEl: '.swiper-button-prev-grade'
        },
        preloadImages: false,
        lazy: true,
        zoom: { maxRatio: 3 }
      });
    },
    setLimparPesquisa() {
      var self = this;
      document.querySelectorAll('.lazy-loaded').forEach(elemento => {
        self.$app.lazy.loadImage(elemento);
      });
      self.$app.lazy.load('.lazy');
    },
    setOnTyping() {
      var self = this, txtPesquisar = document.querySelector('#txtPesquisar');
      clearTimeout(self.typingTimer);
      if (txtPesquisar.value) {
        self.typingTimer = setTimeout(self.setUpdateProdutos, self.doneTypingInterval);
      }
    },
    setUpdateProdutos() {
      var self = this;
      self.$setState({ produtos_pesquisados: self.produtos_pesquisados });
      self.$app.lazy.load('.lazy');
    },
    setVisaoGrade(visao_grade) {
      var self = this;
      self.$setState({ visao_grade: visao_grade });
      document.querySelectorAll('.botao-visao').forEach(e => {
        e.classList.remove('tab-link-active');
      });
      document.querySelector('.visao' + visao_grade).classList.add('tab-link-active');
    },
    setPopup(classe) {
      var self = this;
      self.$app.popup.open(classe, false);
    },
    setAcessaGrade(edicao, idproduto, numpagina, index, tipo) {
      var self = this, edicao = edicao.toString();
      var objeto_produto = tipo === 'grade' ? self.produtos[index] : self.produtos_pesquisados[index];
      self.setPopup('.popup-produto');
      self.$root.memorizaPagina(edicao, 0, numpagina, objeto_produto);
      var promessa = '';
      if (objeto_produto.marca !== 'FIORANI') {
        promessa = self.$root.retornaParametros(Number(edicao), numpagina);
      } else {
        promessa = self.$root.retornaGradesProprios(Number(edicao), numpagina, 'nao');
      }
      promessa.then(function (estoquecatalogoatual) {
        if (self.$app) {
          self.$app.data.estoquecatalogoatual = estoquecatalogoatual.map(function (reg) {
            reg.link = self.$app.data.link_webservice + '/' + reg.link;
            reg.preco = reg.preco;
            reg.precoBR = 'R$ ' + self.$root.formataMoeda(self.$root.setPrecoMargem(reg.preco));
            reg.custoBR = 'R$ ' + self.$root.formataMoeda(reg.preco);
            return reg;
          });
          var objeto = self.$app.data.edicaoPagina.find(function (o) {
            return o.edicao == edicao;
          });
          self.$setState({ fotos: [] });
          self.$setState({
            fotos: objeto_produto.fotos.indexOf('{') >= 0 ? objeto_produto.fotos.parseJSONPG().map(m => {
              m.foto = self.$root.link_agely + m.foto + '#' + self.hack_data;
              return m;
            }) : [{ foto: self.$root.link_s3 + 'catalogos/catalogo' + objeto_produto.fotos + '#' + self.hack_data }],
            referencia: objeto.produto.referencia,
            descricao: objeto.produto.descricaobkp
          });
          self.iniSwiperFotos();
          if (self.$app.data.estoquecatalogoatual !== undefined) {
            self.dadosProdutos = self.$app.data.estoquecatalogoatual.filter(function (o) {
              return o.idproduto == idproduto;
            });
            self.dadosProdutos.map(m => {
              m.edicao = edicao;
              return m;
            });
          } else {
            self.dadosProdutos = [];
          }
          self.$setState({
            loading: false,
            linhas: self.dadosProdutos.map(m => {
              m.tamanho = m.descricao.split(' ').find(f => self.$app.data.array_tamanhos.find(f2 => f2.tamanho === f));
              return m;
            }).filter(function (e) {
              return e.idproduto == idproduto;
            }).map(function (dado) {
              return dado.tamanho;
            }).reduce((a, d) => {
              if (!a.includes(d)) {
                a.push(d);
              }
              return a;
            }, []).filter(function (tamanho) {
              return self.$app.data.array_tamanhos.find(function (objeto) {
                return objeto.tamanho === tamanho;
              }).checado === 'color-blue';
            }).map(function (tamanho) {
              var objeto = self.dadosProdutos.find(function (e) {
                return e.tamanho === tamanho;
              });
              var objeto_Sacola = self.$app.data.array_sacola.find(function (e) {
                return e.idprodutograde === objeto.idprodutograde;
              });
              return {
                edicao: objeto.edicao,
                descricao: objeto.descricao,
                idproduto: objeto.idproduto,
                idprodutosubsecao: objeto.idprodutosubsecao,
                idmarca: objeto.idmarca,
                custoBR: objeto.custoBR,
                idtabelapreco: self.$app.data.idtabelapreco,
                valor: objeto.precoBR,
                preco: objeto.preco,
                tipoEstoque: objeto.tipoEstoque,
                valorsemtab: objeto.precocusto,
                tamanho: tamanho,
                ordem_tamanho: self.$app.data.array_tamanhos.map(function (d) {
                  return d.tamanho;
                }).indexOf(tamanho),
                referencia: objeto.ctrl_conjunto,
                idprodutograde: objeto.idprodutograde,
                quantidade: objeto_Sacola === undefined ? 0 : objeto_Sacola.quantidade,
                maximoproprio: objeto.estoque < 10 ? objeto.estoque : 10,
                fotos: self.dadosProdutos.map(function (o) {
                  return o.link;
                }).reduce((a, d) => {
                  if (!a.includes(d)) {
                    a.push(d);
                  }
                  return a;
                }, [])
              };
            }).sort(self.$root.getOrderBy('ordem_tamanho'))
          });
          if (self.$app.data.resposta !== undefined) {
            self.iniSteppers();
          }
        }
      });
      self.setBadgeSacola();
    },
    setValorSacola(idprodutograde) {
      var self = this;
      self.$root.setValorSacola(idprodutograde, self['stepper-grade-' + idprodutograde].getValue(), self.linhas);
      self.setBadgeSacola();
    },
    setScroll(posicao) {
      this.$root.setScroll('page-pesquisar', posicao);
    },
    setPesquisar() {
      var self = this;
      self.searchbar = self.$app.searchbar.create({
        el: '.searchbar',
        searchContainer: '.list-produtos',
        searchIn: '.item-title-produtos',
        backdrop: false,
        customSearch: true,
        on: {
          search(sb, query, previousQuery) {
            if (query.length > 0) {
              if (self.timeoutquery) {
                clearTimeout(self.timeoutquery);
                self.timeoutquery = null;
              }
              self.timeoutquery = setTimeout(function () {
                self.$setState({ produtos_pesquisados: self.produtos_canonicos.search(query).filter((f, i) => i <= 30) });
                self.arrayedicoespesquisa = self.produtos_pesquisados.filter(f => f.precocusto === 'R$ 0,00').unico({ prop: 'numedicao' }).map(m => m.numedicao);
                if (self.arrayedicoespesquisa.length > 0) {
                  self.numedicaopesquisa = self.arrayedicoespesquisa[0];
                  self.getProdutos3(self.numedicaopesquisa);
                }
              }, 2000);
            } else {
              self.$setState({ produtos_pesquisados: [] });
            }
          },
          enable() {
            document.querySelector('.list-produtos')['classList']['remove']('display-none');
            document.querySelector('.visao-grade-produtos')['classList']['add']('display-none');
          },
          disable() {
            document.querySelector('.list-produtos')['classList']['add']('display-none');
            document.querySelector('.visao-grade-produtos')['classList']['remove']('display-none');
          }
        }
      });
    },
    rolou() {
      var self = this, pagina = document.querySelector('.page-pesquisar'), posicaoatual = pagina.scrollTop, fimdopoco = pagina.scrollHeight - pagina.offsetHeight - 100;
      if (posicaoatual > fimdopoco && self.travareload === false) {
        self.$setState({ travareload: true });
        self.$app.preloader.show();
        self.indiceScroll = self.indiceScroll + 20;
        self.getProdutos2(self.numedicao);
      }
      document.querySelector('#txtPesquisar').blur();
      self.$app.lazy.load('.lazy');
      self.escondeuNavbar = document.querySelector('.hide-bars-on-scroll').classList.contains('navbar-hidden');
    },
    getOutrosProdutos() {
      var self = this;
      self.$setState({ numedicao: self.$app.data.catalogos[self.$app.data.catalogos.indexOf(self.numedicao) + 1] });
      self.$setState({ posicaoedicao: self.$app.data.catalogos.indexOf(self.numedicao) });
      self.getProdutos();
    },
    getProduto() {
      var self = this;
      self.$app.preloader.show();
      self.$app.request.promise.post(self.$app.data.link_webservice_sem_porta + 'GrupoDuzaniWS/ws?metodo=GetProdutos', {
        idpessoa: self.$app.data.idpessoa,
        idempresa: self.$app.data.idempresa,
        idtabelapreco: self.$app.data.idtabelapreco,
        idproduto: 0,
        query: self.searchbar.query
      }).then(JSON.parse).then(function (res) {
        self.$app.preloader.hide();
        if (res.length > 0) {
          self.$setState({
            produtos_pesquisados: [
              ...self['produtos_pesquisados'],
              ...res.map(m => {
                m.precocliente = m.precocliente !== null ? 'R$ ' + self.$root.formataMoeda(m.precocliente) : null;
                m.descricaobkp = m.descricao;
                m.descricao = m.descricao.length > 15 ? m.descricao.substr(0, 50) + '...' : m.descricao;
                m.precoconsultora = m.precoconsultora !== null ? 'R$ ' + self.$root.formataMoeda(m.precoconsultora) : null;
                m.precocusto = m.precocusto !== null ? 'R$ ' + self.$root.formataMoeda(m.precocusto) : null;
                m.saldo = Number(m.saldo);
                return m;
              }).map(m => {
                m.foto = m.fotos.indexOf('{') >= 0 ? self.$root.link_agely + m.fotos.parseJSONPG()[0].foto + '#' + self.hack_data : self.$root.link_s3 + 'catalogos/catalogo' + m.fotos + '#' + self.hack_data;
                return m;
              })
            ].unico({ prop: 'referencia' })
          });
          document.querySelector('.list-produtos').style.display = 'inherit';
          document.querySelector('.searchbar-not-found').style.display = 'none';
          document.querySelectorAll('.hidden-by-searchbar').forEach(li => {
            li.classList.remove('hidden-by-searchbar');
          });
        }
      });
    },
    getProdutos3(edicao, precos) {
      var self = this;
      if (edicao === undefined) {
        self.$app.preloader.show();
      }
      if (edicao === undefined && self.produtos_canonicos.length === 0 || edicao !== undefined && self.produtos_canonicos.length > 0) {
        self.$app.methods.getProdutos({
          idpessoa: self.$app.data.idpessoa,
          idempresa: self.$app.data.idempresa,
          idtabelapreco: self.$app.data.idtabelapreco,
          idproduto: 0,
          numedicao: edicao !== undefined ? edicao : 0
        }).then(JSON.parse).then(function (res) {
          let novosprodutos = res.map(m => {
            m.precocliente = m.precocliente !== null ? 'R$ ' + self.$root.formataMoeda(m.precocliente) : null;
            m.descricaobkp = m.descricao;
            m.descricao = m.descricao.length > 15 ? m.descricao.substr(0, 50) + '...' : m.descricao;
            m.precoconsultora = m.precoconsultora !== null ? 'R$ ' + self.$root.formataMoeda(m.precoconsultora) : null;
            m.precocusto = m.precocusto !== null ? 'R$ ' + self.$root.formataMoeda(m.precocusto) : null;
            m.saldo = Number(m.saldo);
            return m;
          });
          self.$setState({
            produtos_canonicos: self.produtos_canonicos.length === 0 ? [
              ...self.produtos_canonicos,
              ...novosprodutos
            ] : self.produtos_canonicos.map(objeto => {
              let encontrar = novosprodutos.find(f => f.idproduto === objeto.idproduto && f.numedicao === objeto.numedicao);
              if (encontrar !== undefined) {
                objeto = Object.assign(objeto, encontrar);
              }
              return objeto;
            })
          });
          self.$setState({
            produtos_canonicos: self.produtos_canonicos.map(m => {
              m.foto = m.fotos.indexOf('{') >= 0 ? self.$root.link_agely + m.fotos.parseJSONPG()[0].foto + '#' + self.hack_data : self.$root.link_s3 + 'catalogos/catalogo' + m.fotos + '#' + self.hack_data;
              return m;
            })
          });
          self.$setState({
            marcas: [{ marca: 'Todas' }].concat(self.produtos_canonicos.map(m => {
              return { marca: m.marca };
            }).unico({ prop: 'marca' })),
            categorias: [{
                categoria: 'Todas',
                marca: 'Todas'
              }].concat(self.produtos_canonicos.map(m => {
              return {
                categoria: m.categoria,
                marca: m.marca
              };
            }).unico({ prop: 'categoria' })),
            tamanhos: [{ tamanho: 'Todos' }].concat(self.$app.data.array_tamanhos.map(m => {
              return { tamanho: m.tamanho };
            }))
          });
          self.setProdutos();
          if (precos) {
            self.arrayedicoespesquisa = self.produtos_canonicos.filter(f => f.precocusto === 'R$ 0,00').filter(f => self.marca_atual !== 'Todas' ? f.marca === self.marca_atual : f).filter(f => self.categoria_atual !== 'Todas' ? f.categoria === self.categoria_atual : f).filter(f => self.tamanho_atual !== 'Todos' ? f.tamanhos.split(',').find(tamanho => self.tamanho_atual.find(tamanho_atual => tamanho_atual === tamanho)) : f).unico({ prop: 'numedicao' }).map(m => m.numedicao);
          }
          if (self.arrayedicoespesquisa.length > 0) {
            self.arrayedicoespesquisa = self.arrayedicoespesquisa.filter(edicao => edicao != self.numedicaopesquisa);
            self.catalogos = self.catalogos.filter(edicao => edicao != self.numedicaopesquisa);
            self.numedicaopesquisa = self.arrayedicoespesquisa[0];
            self.getProdutos3(self.numedicaopesquisa);
          }
          self.$app.preloader.hide();
        });
      }
    },
    getProdutos2(edicao) {
      var self = this;
      if (edicao === undefined) {
        self.$app.preloader.show();
      }
      self.$app.methods.getProdutos({
        idpessoa: self.$app.data.idpessoa,
        idempresa: self.$app.data.idempresa,
        idtabelapreco: self.$app.data.idtabelapreco,
        idproduto: 0,
        numedicao: edicao !== undefined ? edicao : 0
      }).then(JSON.parse).then(function (res) {
        let novosprodutos = res.map(m => {
          m.precocliente = m.precocliente !== null ? 'R$ ' + self.$root.formataMoeda(m.precocliente) : null;
          m.descricaobkp = m.descricao;
          m.descricao = m.descricao.length > 15 ? m.descricao.substr(0, 50) + '...' : m.descricao;
          m.precoconsultora = m.precoconsultora !== null ? 'R$ ' + self.$root.formataMoeda(m.precoconsultora) : null;
          m.precocusto = m.precocusto !== null ? 'R$ ' + self.$root.formataMoeda(m.precocusto) : null;
          m.saldo = Number(m.saldo);
          return m;
        });
        self.$setState({
          produtos_canonicos: self.produtos_canonicos.length === 0 ? [
            ...self.produtos_canonicos,
            ...novosprodutos
          ] : self.produtos_canonicos.map(objeto => {
            let encontrar = novosprodutos.find(f => f.idproduto === objeto.idproduto && f.numedicao === objeto.numedicao);
            if (encontrar !== undefined) {
              objeto = Object.assign(objeto, encontrar);
            }
            return objeto;
          })
        });
        self.$setState({
          produtos_canonicos: self.produtos_canonicos.map(m => {
            m.foto = m.fotos.indexOf('{') >= 0 ? self.$root.link_agely + m.fotos.parseJSONPG()[0].foto + '#' + self.hack_data : self.$root.link_s3 + 'catalogos/catalogo' + m.fotos + '#' + self.hack_data;
            return m;
          })
        });
        self.$setState({
          marcas: [{ marca: 'Todas' }].concat(self.produtos_canonicos.map(m => {
            return { marca: m.marca };
          }).unico({ prop: 'marca' })),
          categorias: [{
              categoria: 'Todas',
              marca: 'Todas'
            }].concat(self.produtos_canonicos.map(m => {
            return {
              categoria: m.categoria,
              marca: m.marca
            };
          }).unico({ prop: 'categoria' })),
          tamanhos: [{ tamanho: 'Todos' }].concat(self.$app.data.array_tamanhos.map(m => {
            return { tamanho: m.tamanho };
          }))
        });
        self.setProdutos();
        self.$app.preloader.hide();
        self.$setState({ travareload: false });
        self.catalogos = self.catalogos.filter(edicao => edicao != self.numedicao);
        self.numedicao = self.catalogos[0];
      });
    },
    getProdutos(edicao) {
      var self = this;
      self.$app.methods.getProdutos({
        idpessoa: self.$app.data.idpessoa,
        idempresa: self.$app.data.idempresa,
        idtabelapreco: self.$app.data.idtabelapreco,
        idproduto: 0,
        numedicao: edicao !== undefined ? edicao : 0
      }).then(JSON.parse).then(function (res) {
        let novosprodutos = res.map(m => {
          m.precocliente = m.precocliente !== null ? 'R$ ' + self.$root.formataMoeda(m.precocliente) : null;
          m.descricaobkp = m.descricao;
          m.descricao = m.descricao.length > 15 ? m.descricao.substr(0, 50) + '...' : m.descricao;
          m.precoconsultora = m.precoconsultora !== null ? 'R$ ' + self.$root.formataMoeda(m.precoconsultora) : null;
          m.precocusto = m.precocusto !== null ? 'R$ ' + self.$root.formataMoeda(m.precocusto) : null;
          m.saldo = Number(m.saldo);
          return m;
        });
        self.$setState({
          produtos_canonicos: self.produtos_canonicos.length === 0 ? [
            ...self.produtos_canonicos,
            ...novosprodutos
          ] : edicao !== undefined ? self.produtos_canonicos.map(objeto => {
            let encontrar = novosprodutos.find(f => f.idproduto === objeto.idproduto && f.numedicao === objeto.numedicao);
            if (encontrar !== undefined && objeto.precocusto === 'R$ 0,00') {
              objeto = Object.assign(objeto, encontrar);
            }
            return objeto;
          }) : novosprodutos.map(objeto => {
            let encontrar = self.produtos_canonicos.find(f => f.idproduto === objeto.idproduto && f.numedicao === objeto.numedicao);
            if (encontrar !== undefined && objeto.precocusto === 'R$ 0,00') {
              objeto = Object.assign(objeto, encontrar);
            }
            return objeto;
          })
        });
        self.$setState({
          produtos_canonicos: self.produtos_canonicos.map(m => {
            m.foto = m.fotos.indexOf('{') >= 0 ? self.$root.link_agely + m.fotos.parseJSONPG()[0].foto + '#' + self.hack_data : self.$root.link_s3 + 'catalogos/catalogo' + m.fotos + '#' + self.hack_data;
            return m;
          })
        });
        self.$setState({
          marcas: [{ marca: 'Todas' }].concat(self.produtos_canonicos.map(m => {
            return { marca: m.marca };
          }).unico({ prop: 'marca' })),
          categorias: [{
              categoria: 'Todas',
              marca: 'Todas'
            }].concat(self.produtos_canonicos.map(m => {
            return {
              categoria: m.categoria,
              marca: m.marca
            };
          }).unico({ prop: 'categoria' })),
          tamanhos: [{ tamanho: 'Todos' }].concat(self.$app.data.array_tamanhos.map(m => {
            return { tamanho: m.tamanho };
          }))
        });
        document.querySelector('[name=select-tamanhos] option[value="Todos"]').selected = true;
        document.querySelector('[name=select-tamanhos]').parentElement.querySelector('.item-after').textContent = 'Todos';
        document.querySelector('[name=select-marcas] option[value="Todas"]').selected = true;
        document.querySelector('[name=select-marcas]').parentElement.querySelector('.item-after').textContent = 'Todas';
        document.querySelector('[name=select-categorias] option[value="Todas"]').selected = true;
        document.querySelector('[name=select-categorias]').parentElement.querySelector('.item-after').textContent = 'Todas';
        self.setProdutos();
        self.$app.preloader.hide();
        if (self.numedicao >= self.numedicaotravada - 1) {
          let edicaoX = self.numedicao;
          if (self.numedicao === self.numedicaotravada) {
            edicaoX = undefined;
          }
          self.getProdutos(edicaoX);
          self.catalogos = self.catalogos.filter(edicao => edicao != self.numedicao);
          self.numedicao = self.catalogos[0];
        }
      });
    },
    setProdutos() {
      var self = this;
      self.$setState({
        categorias: self.categorias,
        marcas: self.marcas,
        produtos: self.produtos_canonicos.filter(f => self.marca_atual !== 'Todas' ? f.marca === self.marca_atual : f).filter(f => self.categoria_atual !== 'Todas' ? f.categoria === self.categoria_atual : f).filter(f => self.tamanho_atual !== 'Todos' ? f.tamanhos.split(',').find(tamanho => self.tamanho_atual.find(tamanho_atual => tamanho_atual === tamanho)) : f).filter((f, i) => i <= self.indiceScroll).unico({ prop: 'referencia' })
      });
      document.querySelectorAll('.lazy-loaded').forEach(elemento => {
        self.$app.lazy.loadImage(elemento);
      });
      self.$app.lazy.load('.lazy');
    },
    setLimparFiltros() {
      var self = this;
      document.querySelector('[name=select-marcas] option[value="Todas"]').selected = true;
      document.querySelector('[name=select-marcas]').parentElement.querySelector('.item-after').textContent = 'Todas';
      self.setMarca();
    },
    setMarca() {
      var self = this, marca = document.querySelector('[name=select-marcas]').value;
      self.$setState({ marca_atual: marca });
      document.querySelector('[name=select-categorias] option[value="Todas"]').selected = true;
      document.querySelector('[name=select-categorias]').parentElement.querySelector('.item-after').textContent = 'Todas';
      self.setCategoria();
      document.querySelector('[name=select-tamanhos] option[value="Todos"]').selected = true;
      document.querySelector('[name=select-tamanhos]').parentElement.querySelector('.item-after').textContent = 'Todos';
      self.setTamanho(true);
      self.getProdutos3(self.numedicao, true);
      self.$app.popover.close();
    },
    setTamanho(marca) {
      var self = this, tamanho = self.$app.smartSelect.get(document.querySelector('[name=select-tamanhos]').parentElement).getValue();
      if (tamanho.length > 1 && tamanho.indexOf('Todos') >= 0) {
        if (self.tamanho_atual === 'Todos') {
          tamanho.splice(tamanho.indexOf('Todos'), 1);
          document.querySelector('[name=select-tamanhos] option[value="Todos"]').selected = false;
        } else {
          tamanho = 'Todos';
          document.querySelectorAll('[name=select-tamanhos] option').forEach(option => {
            option.selected = false;
          });
          document.querySelector('[name=select-tamanhos] option[value="Todos"]').selected = true;
        }
      } else if (tamanho.length === 1 && tamanho.indexOf('Todos') >= 0 || tamanho.length === 0) {
        tamanho = 'Todos';
        document.querySelector('[name=select-tamanhos] option[value="Todos"]').selected = true;
      }
      self.$app.popover.close();
      setTimeout(() => {
        if (marca !== true) {
          document.querySelector('#smartSelectTamanhos').click();
        }
        document.querySelector('[name=select-tamanhos]').parentElement.querySelector('.item-after').textContent = tamanho === 'Todos' ? tamanho : tamanho.join();
      }, 500);
      self.$setState({ tamanho_atual: tamanho });
      self.getProdutos3(self.numedicao, true);
    },
    setCategoria() {
      var self = this, categoria = document.querySelector('[name=select-categorias]').value;
      self.$setState({ categoria_atual: categoria });
      self.getProdutos3(self.numedicao, true);
      self.$app.popover.close();
    }
  },
  id: '5e6d1d708b',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n  <div class="page no-swipeback" data-name="pesquisar">\r\n\r\n    <!-- PAINEL DOS FILTROS -->\r\n    <div class="panel panel-right panel-cover panel-resizable">\r\n      <div class="block page-content padding-panel margin-top">\r\n        \r\n        <a class="button button-fill color-green margin-bottom panel-close">Filtrar</a>\r\n\r\n        <p class="no-margin margin-bottom-half">Densidade da Lista</p>        \r\n        <div class="segmented segmented-raised color-black margin-bottom">\r\n          <a class="button tab-link botao-visao visao-100" @click="setVisaoGrade(\'-100\')">1x1 <i class="icon f7-icons if-ios text-color-icone">square</i></a>\r\n          <a class="button tab-link botao-visao visao-50 tab-link-active" @click="setVisaoGrade(\'-50\')">2x2 <i class="icon f7-icons if-ios text-color-icone">square_grid_2x2</i></a>\r\n          <a class="button tab-link botao-visao visao-33" @click="setVisaoGrade(\'-33\')">3x3 <i class="icon f7-icons if-ios text-color-icone">square_grid_3x2</i></a>\r\n        </div>\r\n        \r\n        <div class="list no-margin-bottom">\r\n          <ul>\r\n\r\n            <li>\r\n              <a class="item-link smart-select smart-select-init" data-routable-modals="false" data-open-in="popover">\r\n                <select name="select-marcas" @change="setMarca()">\r\n                  ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.marcas, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                    <option value="';
          r += c(ctx_2.marca, ctx_2);
          r += '">';
          r += c(ctx_2.marca, ctx_2);
          r += '</option>\r\n                  ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n                </select>\r\n                <div class="item-content">\r\n                  <div class="item-inner">\r\n                    <div class="item-title">Marcas</div>\r\n                  </div>\r\n                </div>\r\n              </a>\r\n            </li>\r\n          </ul>\r\n        </div>\r\n        <div class="list no-hairlines no-margin">\r\n          <ul>\r\n            <li>\r\n              <a id="smartSelectTamanhos" class="item-link smart-select smart-select-init" data-routable-modals="false" data-open-in="popover">\r\n                <select name="select-tamanhos" multiple @change="setTamanho()">\r\n                  ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.tamanhos, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                    <option value="';
          r += c(ctx_2.tamanho, ctx_2);
          r += '">';
          r += c(ctx_2.tamanho, ctx_2);
          r += '</option>\r\n                  ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n                </select>\r\n                <div class="item-content">\r\n                  <div class="item-inner">\r\n                    <div class="item-title">Tamanhos</div>\r\n                  </div>\r\n                </div>\r\n              </a>\r\n            </li>\r\n          </ul>\r\n        </div>\r\n        <div class="list no-margin margin-bottom-half">\r\n          <ul>\r\n            <li>\r\n              <a class="item-link smart-select smart-select-init" data-routable-modals="false" data-open-in="popover">\r\n                <select name="select-categorias" @change="setCategoria()">\r\n                  ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.categorias, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                    ';
          r += Template7Helpers.js_if.call(ctx_2, '(@root.marca_atual == \'Todas\' || (this.categoria == \'Todas\' || this.marca == @root.marca_atual))', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                      <option value="';
              r += c(ctx_3.categoria, ctx_3);
              r += '">';
              r += c(ctx_3.categoria, ctx_3);
              r += '</option>\r\n                    ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                  ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n                </select>\r\n                <div class="item-content">\r\n                  <div class="item-inner">\r\n                    <div class="item-title">Categorias</div>\r\n                  </div>\r\n                </div>\r\n              </a>\r\n            </li>\r\n\r\n          </ul>\r\n        </div>\r\n\r\n        <a class="button text-color-red text-align-right" @click="setLimparFiltros()">Limpar Filtros</a>\r\n\r\n      </div>\r\n    </div>\r\n\r\n    <!-- NAVBAR DA PESQUISA -->\r\n    <div class="navbar hide-bars-on-scroll">\r\n      <div class="navbar-inner sliding">\r\n        <div class="left">\r\n          <a href="/home/" class="link" data-animate="false">\r\n            <i class="icon icon-back text-color-icone"></i>\r\n            <span class="if-not-md text-color-texto">Voltar</span>\r\n          </a>\r\n        </div>\r\n\r\n        <div class="right">\r\n          <div class="segmented">\r\n            <a class="link icon-only" @click="abrirFiltros()">\r\n              <i class="icon f7-icons text-color-icone">slider_horizontal_3</i>\r\n            </a>\r\n            ';
      r += Template7Helpers.js_if.call(ctx_1, '../$root.resposta == \'true\'', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n            <a href="/sacola/" class="link icon-only">\r\n              ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.mostrar_sacola === \'sim\'', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                <i class="icon f7-icons if-ios text-color-icone">bag<span class="badge color-blue">';
              r += c(ctx_3.total_sacola, ctx_3);
              r += '</span></i>\r\n                <i class="icon material-icons if-md text-color-icone">shopping_basket<span class="badge color-blue">';
              r += c(ctx_3.total_sacola, ctx_3);
              r += '</span></i>\r\n              ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                <i class="icon f7-icons if-ios text-color-icone">bag</i>\r\n                <i class="icon material-icons if-md text-color-icone">shopping_basket</i>\r\n              ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n            </a>\r\n            ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n          </div>\r\n        </div>\r\n        <!-- <span style="display: ';
      r += c(ctx_1.mostracarregamento, ctx_1);
      r += '; position: absolute;top: 29px;font-size: 11px;color: #757575;width: 100%;left: 0px;z-index: 999;">\r\n          <span class="text-align-center width-100" style="position: absolute;">';
      r += c(ctx_1.posicaoedicao, ctx_1);
      r += ' de ';
      r += c(ctx_1.totalcatalogos, ctx_1);
      r += ' catálogos carregados</span>\r\n        </span> -->\r\n        <div class="subnavbar">\r\n          <div class="subnavbar-inner">\r\n            <div class="searchbar">\r\n              <div class="searchbar-inner">\r\n                <div class="searchbar-input-wrap">\r\n                  <input id="txtPesquisar" type="search" placeholder="Procurar" @keyup="setOnTyping()" @click="setBackdrop()">\r\n                  <i class="searchbar-icon"></i>\r\n                  <span class="input-clear-button"></span>\r\n                </div>\r\n                <span class="searchbar-disable-button text-color-texto" @click="setLimparPesquisa()">Cancelar</span>\r\n              </div>\r\n            </div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n\r\n    <div class="page-pesquisar page-content" @scroll="rolou()">\r\n      \r\n      <!-- LISTA DOS PRODUTOS QUANDO CLICA NO PESQUISAR -->\r\n      <div class="list media-list list-produtos searchbar-found display-none no-margin">\r\n        <ul>\r\n          ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.produtos_pesquisados, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n          <li>\r\n            <a href="#" class="item-link item-content" @click="setAcessaGrade(';
          r += c(ctx_2.numedicao, ctx_2);
          r += ',';
          r += c(ctx_2.idproduto, ctx_2);
          r += ',\'';
          r += c(ctx_2.numpagina, ctx_2);
          r += '\',';
          r += c(data_2 && data_2.index, ctx_2);
          r += ', \'pesquisa\')">\r\n              <div class="item-media"><img class="fotos-pesquisar" src="';
          r += c(ctx_2.foto, ctx_2);
          r += '" width="80" /></div>\r\n              <div class="item-inner">\r\n                <div class="item-title-row">\r\n                  <div class="item-title item-title-produtos">';
          r += c(ctx_2.referencia, ctx_2);
          r += '</div>\r\n                  ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.precocliente == \'R$ 0,00\'', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                  <div class="item-after item-title-produtos">xxxxx</div>\r\n                  ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                  <div class="item-after item-title-produtos">';
              r += c(ctx_3.precocliente, ctx_3);
              r += '</div>\r\n                  ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                </div>\r\n                ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.saldo == 0', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                <div class="item-subtitle text-color-red">ESGOTADO</div>\r\n                ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                <div class="item-text item-title-produtos">';
          r += c(ctx_2.descricao, ctx_2);
          r += '</div>\r\n                <div class="item-text text-color-texto">';
          r += c(ctx_2.tamanhos, ctx_2);
          r += '</div>\r\n              </div>\r\n            </a>\r\n          </li>\r\n          ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n        </ul>\r\n      </div>\r\n\r\n      <!-- ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.produtos_pesquisados.length > 0', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n      <div class="row margin">\r\n        <div class="col">\r\n          <button class="link button button-fill color-blue" @click="getProduto()">continuar pesquisando</button>\r\n        </div>\r\n      </div>\r\n      ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' -->\r\n      \r\n      <!-- APARECE QUANDO NÃO ENCONTRA NADA -->\r\n      <div class="block searchbar-not-found">\r\n        <div class="block-inner">\r\n          <p>não encontrado</p>\r\n        </div>\r\n        <!-- <div class="row margin">\r\n          <div class="col">\r\n            <button class="link button button-fill color-blue" @click="getProduto()">continuar pesquisando</button>\r\n          </div>\r\n        </div> -->\r\n      </div>\r\n\r\n      <!-- GRADE PRINCIPAL DA TELA DE PESQUISA -->\r\n      <div class="visao-grade-produtos block margin-top-half">\r\n        <div class="row margin-top-half">\r\n          ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.produtos, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n            <div class="col';
          r += c(root.visao_grade, ctx_2);
          r += ' margin-top-half bg-catalogo">\r\n              <a href="#" class="item-link item-content" @click="setAcessaGrade(';
          r += c(ctx_2.numedicao, ctx_2);
          r += ',';
          r += c(ctx_2.idproduto, ctx_2);
          r += ',\'';
          r += c(ctx_2.numpagina, ctx_2);
          r += '\',';
          r += c(data_2 && data_2.index, ctx_2);
          r += ', \'grade\')">\r\n                <img class="fotos-pesquisar width-100" src="';
          r += c(ctx_2.foto, ctx_2);
          r += '" />\r\n                <div class="produto-descricao text-color-black margin-top margin-bottom">';
          r += c(ctx_2.referencia, ctx_2);
          r += ' - ';
          r += c(ctx_2.descricao, ctx_2);
          r += '</div>\r\n                <div class="text-color-texto">';
          r += c(ctx_2.tamanhos, ctx_2);
          r += '</div>\r\n                ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.precocliente == \'R$ 0,00\'', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                <div class="produto-preco text-color-dourado margin-bottom skeleton-text skeleton-effect-blink ">xxxxx</div>\r\n                ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                <div class="produto-preco text-color-dourado margin-bottom">';
              r += c(ctx_3.precocliente, ctx_3);
              r += '</div>\r\n                ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.saldo > 0', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                <button class="button button-fill color-verde botao-pedir">PEDIR</button>\r\n                ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                <button class="button button-fill color-red botao-pedir">ESGOTADO</button>\r\n                ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n              </a>\r\n            </div>\r\n          ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n        </div>\r\n        <!-- <div class="row margin-top-half">\r\n          <button class="button button-fill color-blue" @click="getOutrosProdutos()">Carregar mais</button>\r\n        </div> -->\r\n      </div>\r\n\r\n      <!-- POPUP PEDIDOS -->\r\n      <div class="popup popup-pedidos">\r\n\r\n        <div class="navbar">\r\n          <div class="navbar-inner sliding">\r\n            <div class="left">\r\n              <a href="#" class="link popup-close sheet-close">\r\n                <i class="icon icon-back text-color-icone"></i>\r\n                <span class="if-not-md text-color-texto">Voltar</span>\r\n              </a>\r\n            </div>\r\n            <div class="title text-color-texto">Pedidos</div>\r\n            <div class="right">\r\n              <a href="#" class="link icon-only" @click="getPedidos()">\r\n                <i class="icon material-icons if-md text-color-icone">refresh</i>\r\n                <i class="icon f7-icons if-ios text-color-icone">arrow_clockwise</i>\r\n              </a> \r\n            </div>\r\n          </div>\r\n        </div>\r\n        \r\n        <div class="page-content bg-color-white" style="overflow: auto;">\r\n          ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.pedidos != undefined && this.pedidos.length > 0', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n          <div class="list list-pedidos">\r\n            <ul>\r\n              ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.pedidos, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n              <li>\r\n                <a href="#" class="item-content item-link sheet-open" data-sheet=".sheet-itens-pedido" @click="abrirItens(';
              r += c(ctx_3.idcatalogo, ctx_3);
              r += ')">\r\n                  <div class="item-inner">\r\n                    <div class="item-title">\r\n                      ';
              r += c(ctx_3.datapedido, ctx_3);
              r += '\r\n                      <div class="item-footer">';
              r += c(ctx_3.valortotal, ctx_3);
              r += '</div>\r\n                    </div>\r\n                    <div class="item-after">\r\n                      <span class="status-fundo bg-color-';
              r += c(ctx_3.cor, ctx_3);
              r += ' text-color-';
              r += c(ctx_3.cor, ctx_3);
              r += '">';
              r += c(ctx_3.status, ctx_3);
              r += '</span>\r\n                      <span class="status-texto text-color-black">';
              r += c(ctx_3.status, ctx_3);
              r += '</span>\r\n                    </div>\r\n                  </div>\r\n                </a>\r\n              </li>\r\n              ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n            </ul>\r\n          </div>\r\n          ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n\r\n          <div class="sheet-modal sheet-itens-pedido">\r\n            <div class="toolbar bg-color-black">\r\n              <div class="toolbar-inner">\r\n                <div class="left"></div>\r\n                <div class="right">\r\n                  <a class="link" href="#" @click="setFecharItensPedido()">Fechar</a>\r\n                </div>\r\n              </div>\r\n            </div>\r\n            <div class="sheet-modal-inner">\r\n              <div class="list page-content list-itens-pedido">\r\n                <ul>\r\n                  ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.itens, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                  <li class="item-content no-chevron">\r\n                    <div class="item-inner">\r\n                      <div class="item-title">';
          r += c(ctx_2.referencia, ctx_2);
          r += ' - <b>';
          r += c(ctx_2.tamanho, ctx_2);
          r += '</b> Qtde.:';
          r += c(ctx_2.quantidade, ctx_2);
          r += '</div>\r\n                      <div class="item-after"><b>';
          r += c(ctx_2.valortotalBR, ctx_2);
          r += '</b></div>\r\n                    </div>\r\n                  </li>\r\n                  ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n                </ul>\r\n              </div>\r\n            </div>\r\n          </div>\r\n\r\n        </div>\r\n      </div>\r\n      \r\n      <!-- POPUP PRODUTO -->\r\n      <div class="popup popup-produto">\r\n      \r\n        <div class="navbar">\r\n          <div class="navbar-inner sliding">\r\n            <div class="left">\r\n              <a href="#" class="link popup-close">\r\n                <i class="icon icon-back text-color-icone"></i>\r\n                <span class="if-not-md text-color-texto">Voltar</span>\r\n              </a>\r\n            </div>\r\n            <div class="title text-color-texto">Ref.: ';
      r += c(ctx_1.referencia, ctx_1);
      r += '</div>\r\n            <div class="right tabbar-labels">\r\n              ';
      r += Template7Helpers.js_if.call(ctx_1, '../$root.resposta == \'true\'', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n              <a href="/sacola/" class="link icon-only popup-close">\r\n                ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.mostrar_sacola === \'sim\'', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                  <i class="icon f7-icons if-ios text-color-icone">bag<span class="badge color-blue">';
              r += c(ctx_3.total_sacola, ctx_3);
              r += '</span></i>\r\n                  <i class="icon material-icons if-md text-color-icone">shopping_basket<span class="badge color-blue">';
              r += c(ctx_3.total_sacola, ctx_3);
              r += '</span></i>\r\n                ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                  <i class="icon f7-icons if-ios text-color-icone">bag</i>\r\n                  <i class="icon material-icons if-md text-color-icone">shopping_basket</i>\r\n                ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n              </a>\r\n              ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n            </div>\r\n          </div>\r\n        </div>\r\n  \r\n        <div class="page-content page-popup bg-color-white no-padding">\r\n        \r\n          <div class="row">\r\n            <div class="col-auto tablet-15"></div>\r\n            <div class="col-auto tablet-70">\r\n    \r\n              ';
      r += '\r\n    \r\n                <div class="row">\r\n                  <div class="col-auto tablet-30"></div>\r\n                  \r\n                  <div class="col-auto tablet-40">\r\n                    <div class="swiper-container swiper-container-horizontal swiper-fotos-grade margin">\r\n                      <div class="swiper-wrapper">\r\n                        ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.fotos, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                          <div class="swiper-slide text-align-center">                      \r\n                            <div class="swiper-zoom-container">\r\n                              <img class="swiper-lazy" data-src="';
          r += c(ctx_2.foto, ctx_2);
          r += '" />\r\n                              <div class="swiper-lazy-preloader"></div>\r\n                            </div>\r\n                          </div>\r\n                        ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '  \r\n                      </div>\r\n                      <div class="swiper-button-prev swiper-button-prev-grade padding text-color-texto outline"></div>\r\n                      <div class="swiper-button-next swiper-button-next-grade padding text-color-texto outline"></div>\r\n                    </div>\r\n                  </div>\r\n    \r\n                  <div class="col-auto tablet-30"></div>\r\n                </div>\r\n                \r\n                <div class="block no-margin-bottom">\r\n                  <h2>SELECIONE OS TAMANHOS</h2>\r\n                </div>\r\n                \r\n                <div class="list no-margin no-hairlines">\r\n                  <ul>\r\n                    ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.loading, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                      <li class="item-content no-chevron skeleton-text skeleton-effect-blink">\r\n                        <div class="item-content width-100">\r\n                          <div class="item-media">\r\n                            <div class="skeleton-block" style="width: 40px; height: 40px; border-radius: 50%"></div>\r\n                          </div>\r\n                          <div class="item-inner">\r\n                            <div class="item-title item-title-valor">333333</div>\r\n                            <div class="item-title item-referencia-tamanho">333333 1 <b>22</b></div>\r\n                          </div>\r\n                        </div>\r\n                      </li>\r\n                    ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                      \r\n                      ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.linhas, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                      <li class="item-content no-chevron ';
              r += c(root.swipeout, ctx_3);
              r += '">\r\n                        <div class="item-content swipeout-content width-100">\r\n                          <div class="item-media">\r\n                          ';
              r += Template7Helpers.each.call(ctx_3, ctx_3.fotos, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n                            <img src="';
                  r += c(ctx_4, ctx_4);
                  r += '" class="foto-sistema" />\r\n                          ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\r\n                          </div>\r\n                          \r\n                          <div class="item-inner">\r\n                            <div class="item-title"><b>';
              r += c(ctx_3.tamanho, ctx_3);
              r += '</b></div>\r\n                            <div class="item-after">\r\n                              ';
              r += Template7Helpers.js_if.call(ctx_3, '../$root.resposta == \'true\'', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n                              <div class="stepper-grade-';
                  r += c(ctx_4.idprodutograde, ctx_4);
                  r += ' stepper stepper-fill stepper-init color-green">\r\n                                <div class="stepper-button-minus"></div>\r\n                                <div class="stepper-input-wrap">\r\n                                  <input type="text" value="';
                  r += c(ctx_4.quantidade, ctx_4);
                  r += '" min="0" max="';
                  r += c(ctx_4.maximoproprio, ctx_4);
                  r += '" step="1" readonly @change="setValorSacola(';
                  r += c(ctx_4.idprodutograde, ctx_4);
                  r += ')">\r\n                                </div>\r\n                                <div class="stepper-button-plus"></div>\r\n                              </div>\r\n                              ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n                              <button class="button button-fill bg-color-whatsapp" @click="compartilharProduto(';
                  r += c(ctx_4.idprodutograde, ctx_4);
                  r += ');">COMPARTILHAR</button>\r\n                              ';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\r\n    \r\n                            </div>\r\n                          </div>\r\n                          \r\n                        </div>\r\n                        ';
              r += Template7Helpers.js_if.call(ctx_3, '@root.swipeout == \'swipeout\'', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n                        <div class="swipeout-actions-right">\r\n                          <a href="#" class="text-color-white bg-color-dourado">';
                  r += c(ctx_4.custoBR, ctx_4);
                  r += '</a>\r\n                        </div>\r\n                        ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\r\n                      </li>\r\n                      ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                    ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n                  </ul>\r\n                </div>\r\n\r\n                <div class="block text-color-black no-margin margin-top-half">\r\n                  <a class="text-color-black" @click="setAbrirPopupTamanho()"><img src="';
      r += c(ctx_1.$root.link_catalogo, ctx_1);
      r += 'img/ruler_icon.png" width="25" height="14"> Tabela de Tamanhos</a>\r\n                </div>                \r\n\r\n                ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.linhas, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.linhas[0], {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                ';
              r += Template7Helpers.if.call(ctx_3, ctx_3.linhas[0].valor, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n                ';
                  r += Template7Helpers.js_if.call(ctx_4, '../$root.resposta == \'true\'', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\r\n                  <div class="block text-color-black font-size-30 no-margin margin-top-half">';
                      r += c(ctx_5.linhas[0].valor, ctx_5);
                      r += '</div>\r\n                ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\r\n                ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\r\n                ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n    \r\n                ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.referencia, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                <div class="block no-margin-bottom">\r\n                  <h2 class="no-margin">DETALHES DO PRODUTO</h2>\r\n                  <p class="no-margin text-align-justify">';
          r += c(ctx_2.descricao, ctx_2);
          r += '</p>\r\n                </div>\r\n                ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n            </div>\r\n            <div class="col-auto tablet-15"></div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n\r\n      <!-- POPUP TABELA DE TAMANHO -->\r\n      <div class="popup popup-tamanho">\r\n\r\n        <div class="navbar">\r\n          <div class="navbar-inner sliding">\r\n            <div class="left">\r\n              <a href="#" class="link" @click="setReabrePopupProduto()">\r\n                <i class="icon icon-back text-color-icone"></i>\r\n                <span class="if-not-md text-color-texto">Voltar</span>\r\n              </a>\r\n            </div>\r\n          </div>\r\n        </div>\r\n        <div class="page-content page-popup bg-color-white no-padding">\r\n          <div class="swiper-container swiper-container-horizontal swiper-fotos-tamanhos margin">\r\n            <div class="swiper-wrapper">\r\n              <div class="swiper-slide text-align-center">\r\n                <div class="swiper-zoom-container">\r\n                  <img src="';
      r += c(ctx_1.$root.link_catalogo, ctx_1);
      r += 'img/tabela02.jpg" />\r\n                </div>\r\n              </div>\r\n            <div class="swiper-slide text-align-center">\r\n              <div class="swiper-zoom-container">\r\n                <img src="';
      r += c(ctx_1.$root.link_catalogo, ctx_1);
      r += 'img/tabela01.jpg" />\r\n              </div>\r\n            </div>\r\n            </div>\r\n            <div class="swiper-button-prev swiper-button-prev-tamanhos padding text-color-texto outline"></div>\r\n            <div class="swiper-button-next swiper-button-next-tamanhos padding text-color-texto outline"></div>\r\n          </div>\r\n        </div>\r\n\r\n      </div>\r\n\r\n    </div>\r\n\r\n    <!-- BOTÃO DE VOLTAR PARA O TOPO -->\r\n    <div class="fab fab-right-bottom color-black">\r\n      <a href="#" class="link" @click="setScroll(0)">\r\n        <i class="icon f7-icons">chevron_up</i>\r\n      </a>\r\n    </div>\r\n\r\n  </div>\r\n';
      return r;
    }(this);
  },
  style: `
  .padding-panel {
    padding-bottom: 100px !important;
  }
  div[class*="col"] img {
    background: #fff;
  }
  .page-popup {
    padding-bottom: 200px !important;
  }
  .font-size-30 {
    font-size: 30px !important;
  }
  .sheet-sacola {
    height: 128px !important;
  }
  .list-sacola {
    padding-bottom: 150px !important;
    margin-top: 0px !important;
  }
  .list-itens-pedido {
    padding-bottom: 100px !important;
  }
  .md .status-texto {
    position: absolute;
    bottom: 16px;
    right: 46px;
    font-weight: 500;
  }
  .ios .status-texto {
    position: absolute;
    bottom: 16px;
    right: 38px;
    font-weight: 500;
  }
  .status-fundo {
    filter: opacity(0.5);
    border-radius: 20px;
    padding: 4px;
    font-weight: 500;
  }
  .list-pedidos {
    padding-bottom: 150px !important;
    margin-top: 0px !important;
  }
`,
  styleScoped: false
};
    