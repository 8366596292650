export default {
  on: {
    pageBeforeIn: function (e, page) {
      var self = this;
      self.$root.verificaInternet();
      if (navigator.splashscreen !== undefined) {
        navigator.splashscreen.show();
      }
      self.$root.verificaStorage().then(function (armazenado) {
        if (armazenado !== undefined) {
          self.$app.data = {
            ...self['$app']['data'],
            ...armazenado
          };
        }
        self.$root.carregaJS('https://aplicativo.sistemaagely.com.br/duzani/imask.js', () => {
        });
        self.$root.carregaJS('https://aplicativo.sistemaagely.com.br/duzani/variaveisIniciaisDuzaniNovo.js?' + new Date(), function () {
          self.$app.data.link_webservice = variaveis_iniciais.link_webservice;
          self.$app.data.link_webservice_sem_porta = variaveis_iniciais.link_webservice;
          self.$app.data.link_s3 = variaveis_iniciais.link_s3;
          self.$app.data.link_agely = variaveis_iniciais.link_agely;
          self.$app.data.link_catalogo = variaveis_iniciais.link_catalogo;
          self.$app.data.link_chat = variaveis_iniciais.link_chat;
          self.$app.data.link_duzani = variaveis_iniciais.link_duzani;
          self.$app.data.telefone_whatsapp = variaveis_iniciais.telefone_whatsapp;
          self.$app.data.array_tamanhos = self.$root.setMapeaTamanhos();
          self.$setState({ logo_loading: self.$app.data.link_catalogo + 'assets/imgduzani/logoDuzani.png' });
          self.$app.lazy.loadImage('.lazy-logo-loading');
          self.$root.getLinkCompleto().then(function (link) {
            self.$app.data.link_webservice = link;
            let params = new URLSearchParams(window.location.search);
            let page = params.get('p');
            if (page !== null && page === 'recuperar') {
              self.$app.view.main.router.navigate('/recuperar/', { animate: false });
            } else {
              if (self.$app.data.resposta === undefined) {
                self.$app.view.main.router.navigate('/login/', { animate: false });
              } else {
                self.$app.view.main.router.navigate('/home/', { animate: false });
              }
            }
          });
        });
      });
    }
  },
  id: '5322954b43',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n  <div class="page no-swipeback" data-name="loading">\r\n    <div class="page-content bg-color-primary">\r\n      <div class="content-logo">\r\n        <img data-src="';
      r += c(ctx_1.logo_loading, ctx_1);
      r += '" class="lazy lazy-logo-loading lazy-fade-in" />\r\n      </div>\r\n    </div>\r\n  </div>\r\n';
      return r;
    }(this);
  },
  style: `
  .content-logo {
    height: 100vh;
    position: relative;
  }
  .content-logo img {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 50vw;
  }
`,
  styleScoped: false
};