
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return { loading: true };
  },
  on: {
    pageBeforeIn: function () {
      var self = this;
      self.$setState({ loading: false });
      self.$app.ultimatela = self.$app.view.main.history[self.$app.view.main.history.length - 1];
      self.initMargem();
    },
    pageBeforeOut: function (e, page) {
      var self = this, el = '.stepper-margem';
      if (self.$app.stepper.get(el) !== undefined && self.$app.data.margem != self.$app.stepper.get(el).getValue()) {
        self.salvarMargem();
      }
    }
  },
  methods: {
    setInferirMargem: function () {
      var self = this;
      self.$root.setPersisteDados();
      self.$root.recalculaValores();
      self.initMargem();
    },
    initMargem: function () {
      var self = this;
      var el = '.stepper-margem';
      self.stepper_margem = self.$app.stepper.get(el);
      self.$app.stepper.get(el).setValue(Number(self.$app.data.margem));
      self.$setState({ margem: self.$app.data.margem });
    },
    salvarMargem: function () {
      var self = this;
      var el = '.stepper-margem';
      self.$app.data.margem = self.$app.stepper.get(el).getValue();
      self.$root.recalculaValores();
      self.$root.setPersisteDados();
      self.$app.preloader.show();
      self.$root.salvaMargem().then(function () {
        self.$app.toast.create({
          text: 'margem alterada!',
          position: 'bottom',
          closeTimeout: 2000
        }).open();
        setTimeout(() => {
          self.$app.preloader.hide();
          self.$app.view.main.router.navigate('/home/', { animate: false });
        }, 2000);
      });
    }
  },
  id: 'eb823b58d5',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n  <div class="page no-swipeback" data-name="margem">\r\n    \r\n    <div class="navbar">\r\n      <div class="navbar-inner sliding">\r\n        <div class="left">\r\n          <a href="/home/" class="link" data-animate="false">\r\n            <i class="icon icon-back text-color-icone"></i>\r\n            <span class="if-not-md text-color-texto">Voltar</span>\r\n          </a>\r\n        </div>\r\n        <div class="title text-color-texto">Margem</div>\r\n        <div class="right">\r\n          \r\n        </div>\r\n      </div>\r\n    </div>\r\n    \r\n    ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.loading, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n      ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n    <div class="toolbar toolbar-bottom bg-color-white no-shadow">\r\n      <div class="toolbar-inner">\r\n        <a href="#" class="button button-fill color-green width-100 margin" @click="salvarMargem()">Salvar</a>\r\n      </div>\r\n    </div>\r\n    ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n\r\n    <div class="page-content bg-color-white">\r\n      ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.loading, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n      ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n      <p class="margin">Selecione a sua margem de lucro na qual você consultora irá trabalhar! Assim que alterar os valores dos produtos serão calculados com a margem selecionada.</p>\r\n\r\n      <p class="margin">O modelo de cálculo utiliza-se o markup, ou seja, o valor de compra adicionando o percentual da margem.</p>\r\n      \r\n      <p class="margin no-margin-bottom">Ex.: Valor Compra: R$ 100,00</p>\r\n      <p class="margin">Valor Venda: R$ 150,00</p>\r\n\r\n      <div class="block text-align-center">\r\n        <div class="stepper-margem stepper stepper-fill stepper-init color-orange">\r\n          <div class="stepper-button-minus"></div>\r\n          <div class="stepper-input-wrap">\r\n            <input type="text" value="';
          r += c(ctx_2.margem, ctx_2);
          r += '" min="0" max="100" step="1">\r\n          </div>\r\n          <div class="stepper-button-plus"></div>\r\n        </div>      \r\n      </div>\r\n\r\n      <p class="margin">Recomendamos que utilize a margem de 50% como markup. Mas fique a vontade em configurar de acordo com a sua necessidade.</p>\r\n\r\n      <p class="margin">Lembrando que esta configuração não altera o seu valor do produto no pedido, ela somente mostra no aplicativo o valor do produto para o seu cliente.</p>\r\n      ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n    </div>\r\n\r\n  </div>\r\n';
      return r;
    }(this);
  },
  style: `
  .dialog-button {
    color: #282a36 !important;
  }
  .dialog-buttons > span:nth-child(1) {
    display: none
  }
`,
  styleScoped: false
};
    