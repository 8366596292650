
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      itens: [
        1,
        2,
        3
      ],
      load: false
    };
  },
  on: {
    pageBeforeIn: function (page, i) {
      var self = this;
      self.$app.panel.close();
      self.getPedidos(true, false);
      if (document.querySelector('.panel-backdrop') !== null) {
        document.querySelector('.panel-backdrop').remove();
      }
    }
  },
  methods: {
    incrementaPedido: function (array_pedidos) {
      var self = this;
      return (self.pedidos === undefined ? array_pedidos : self.$root.getMergeByProp([
        self['pedidos'],
        array_pedidos
      ], 'numpedido')).sort(self.$root.getOrderBy('-timestamp'));
    },
    init: function (array_pedidos, hoje, proprio) {
      var self = this;
      if (array_pedidos.length > 0) {
        self.$setState({ pedidos: self.incrementaPedido(array_pedidos) });
      }
      self.$setState({ load: hoje === false && proprio === true ? true : false });
      if (hoje === true && proprio === false) {
        self.getPedidos(false, false);
      } else if (hoje === false && proprio === false) {
        self.getPedidos(true, true);
      } else if (hoje === true && proprio === true) {
        self.getPedidos(false, true);
      } else if (hoje === false && proprio === true) {
        self.$app.progressbar.hide();
      }
    },
    abrirItens: function (idcatalogo) {
      var self = this;
      self.$setState({
        itens: self.pedidos.find(function (pedido) {
          return pedido.idcatalogo === idcatalogo;
        }).itens.map(function (item) {
          var dados;
          var array_descricao = item.descricao.split(' ');
          return {
            referencia: dados !== undefined ? dados.ctrl_conjunto : array_descricao.find(function (e) {
              return !isNaN(e);
            }),
            tamanho: dados !== undefined ? dados.tamanho : array_descricao.find(function (e) {
              return self.$app.data.array_tamanhos.map(function (d) {
                return d.tamanho;
              }).indexOf(e) >= 0;
            }),
            valortotalBR: 'R$ ' + self.$root.formataMoeda(item.valortotal),
            quantidade: item.quantidade,
            descricao: item.descricao
          };
        }).filter(function (e) {
          return e.quantidade > 0;
        })
      });
    },
    getPedidos: function (hoje, proprio) {
      var self = this;
      if (hoje === true && proprio === false) {
        self.$app.progressbar.show('dourado');
      }
      if (hoje) {
        self.$root.getPedidosHoje(proprio).then(function (pedidos) {
          self.renderizaListaPedidos(pedidos, hoje, proprio);
        });
      } else {
        self.$root.getPedidos(proprio).then(function (pedidos) {
          self.renderizaListaPedidos(pedidos, hoje, proprio);
        });
      }
    },
    renderizaListaPedidos: function (pedidos, hoje, proprio) {
      var self = this;
      var status = 'Aguardando', cor = 'purple';
      var array_pedidos = pedidos.filter(function (pedido) {
        return pedido.arrayCatalogoItensObjeto.find(function (item) {
          return item.descricao.indexOf('Catalogo') >= 0;
        });
      }).map(function (pedido) {
        if (pedido.tipoestoque === 'catalogo') {
          if (pedido.excluido === false && pedido.dataaprovado === 'null' && pedido.datafaturado === 'null') {
            status = 'Aguardando';
            cor = 'purple';
          } else if (pedido.excluido === true && pedido.dataaprovado !== 'null' && pedido.datafaturado === 'null') {
            status = 'Rejeitado';
            cor = 'red';
          } else if (pedido.excluido === false && pedido.dataaprovado !== 'null' && pedido.datafaturado === 'null') {
            status = 'Aprovado';
            cor = 'green';
          } else if (pedido.excluido === false && pedido.datafaturado !== 'null') {
            if (pedido.tipo === 'faturadofabrica') {
              status = 'Faturado Fábrica';
              cor = 'blue';
            } else if (pedido.tipo === 'importado') {
              status = 'Importado';
              cor = 'yellow';
            } else if (pedido.tipo === 'faturadocliente') {
              status = 'Faturado Cliente';
              cor = 'lime';
            }
          }
        } else if (pedido.tipoestoque === 'distribuidor') {
          if (pedido.excluido === false && pedido.tipo === 'agmobile') {
            status = 'Aguardando (Fiorani)';
            cor = 'purple';
          } else if (pedido.excluido === true && pedido.tipo === 'agmobile') {
            status = 'Rejeitado (Fiorani)';
            cor = 'red';
          } else if (pedido.excluido === false && pedido.tipo === 'pendente') {
            status = 'Aprovado (Fiorani)';
            cor = 'blue';
          }
        }
        return {
          datapedido: self.$root.getData(pedido.data),
          timestamp: self.$root.getDataTimestamp(pedido.data),
          numpedido: pedido.numpedido,
          idcatalogo: pedido.idcatalogo,
          valortotal: 'R$ ' + (pedido.valortotal > 0 ? self.$root.formataMoeda(pedido.valortotal) : self.$root.formataMoeda(pedido.arrayCatalogoItensObjeto.map(function (item) {
            return item.valortotal;
          }).reduce(function (a, d) {
            return a + d;
          }))),
          itens: pedido.arrayCatalogoItensObjeto.map(function (item) {
            return {
              descricao: item.descricao,
              idproduto: item.idproduto,
              idprodutograde: item.idprodutograde,
              idprodutosubsecao: item.idprodutosubsecao,
              quantidade: item.quantidade,
              valortotal: item.valortotal
            };
          }),
          status: status,
          cor: cor
        };
      });
      self.init(array_pedidos, hoje, proprio);
    }
  },
  id: 'a7dbf9c6ce',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n  <div class="page no-swipeback" data-name="pedidos">\r\n    <div class="navbar">\r\n      <div class="navbar-inner sliding">\r\n        <div class="left">\r\n          <a href="/home/" class="link" data-animate="false">\r\n            <i class="icon icon-back text-color-icone"></i>\r\n            <span class="if-not-md text-color-texto">Voltar</span>\r\n          </a>\r\n        </div>\r\n        <div class="title text-color-texto">Pedidos</div>\r\n        <div class="right">\r\n          <a href="#" class="link icon-only" @click="getPedidos(true, false)">\r\n            <i class="icon material-icons if-md text-color-icone">refresh</i>\r\n            <i class="icon f7-icons if-ios text-color-icone">arrow_clockwise</i>\r\n          </a> \r\n        </div>\r\n      </div>\r\n    </div>\r\n    \r\n    <div class="page-content bg-color-white" style="overflow: auto;">\r\n      ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.pedidos != undefined && this.pedidos.length > 0', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n      <div class="list list-pedidos">\r\n        <ul>\r\n          ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.pedidos, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n          <li>\r\n            <a href="#" class="item-content item-link sheet-open" data-sheet=".sheet-itens-pedido" @click="abrirItens(';
              r += c(ctx_3.idcatalogo, ctx_3);
              r += ')">\r\n              <div class="item-inner">\r\n                <div class="item-title">\r\n                  ';
              r += c(ctx_3.datapedido, ctx_3);
              r += '\r\n                  <div class="item-footer">';
              r += c(ctx_3.valortotal, ctx_3);
              r += '</div>\r\n                </div>\r\n                <div class="item-after">\r\n                  <span class="status-fundo bg-color-';
              r += c(ctx_3.cor, ctx_3);
              r += ' text-color-';
              r += c(ctx_3.cor, ctx_3);
              r += '">';
              r += c(ctx_3.status, ctx_3);
              r += '</span>\r\n                  <span class="status-texto text-color-black">';
              r += c(ctx_3.status, ctx_3);
              r += '</span>\r\n                </div>\r\n              </div>\r\n            </a>\r\n          </li>\r\n          ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n        </ul>\r\n      </div>\r\n      ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.pedidos != undefined && this.load == true', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n        ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.pedidos != undefined && this.load == true', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n        <p class="text-align-center">Você ainda não pediu nenhum produto!</p>\r\n        ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n      ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n    </div>\r\n\r\n    <div class="sheet-modal sheet-itens-pedido">\r\n      <div class="toolbar bg-color-black">\r\n        <div class="toolbar-inner">\r\n          <div class="left"></div>\r\n          <div class="right"><a class="link sheet-close" href="#">Fechar</a></div>\r\n        </div>\r\n      </div>\r\n      <div class="sheet-modal-inner">\r\n        <div class="list page-content list-itens-pedido">\r\n          <ul>\r\n            ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.itens, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n            <li class="item-content no-chevron">\r\n              <div class="item-inner">\r\n                <div class="item-title">';
          r += c(ctx_2.referencia, ctx_2);
          r += ' - <b>';
          r += c(ctx_2.tamanho, ctx_2);
          r += '</b> Qtde.:';
          r += c(ctx_2.quantidade, ctx_2);
          r += '</div>\r\n                <div class="item-after"><b>';
          r += c(ctx_2.valortotalBR, ctx_2);
          r += '</b></div>\r\n              </div>\r\n            </li>\r\n            ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n          </ul>\r\n        </div>\r\n      </div>\r\n    </div>\r\n\r\n  </div>\r\n';
      return r;
    }(this);
  },
  style: `
  .list-itens-pedido {
    padding-bottom: 100px !important;
  }
  .md .status-texto {
    position: absolute;
    bottom: 16px;
    right: 46px;
    font-weight: 500;
  }
  .ios .status-texto {
    position: absolute;
    bottom: 16px;
    right: 38px;
    font-weight: 500;
  }
  .status-fundo {
    filter: opacity(0.5);
    border-radius: 20px;
    padding: 4px;
    font-weight: 500;
  }
  .list-pedidos {
    padding-bottom: 150px !important;
    margin-top: 0px !important;
  }
  .dialog-button {
    color: #282a36 !important;
  }
  /* .dialog-buttons > span:nth-child(1) {
    display: none
  } */
`,
  styleScoped: false
};
    