
import Home from "../pages/home.f7.html?v=4";
import Catalogo from '../pages/catalogo.f7.html?v=4';
import Grades from '../pages/grades.f7.html?v=4';
import Produtos from '../pages/produtos.f7.html?v=4';
import Margem from '../pages/margem.f7.html?v=4';
import Sacola from '../pages/sacola.f7.html?v=4';
import Senha from '../pages/senha.f7.html?v=4';
import Pedidos from '../pages/pedidos.f7.html?v=4';
import Configuracao from '../pages/configuracao.f7.html?v=4';
import Login from '../pages/login.f7.html?v=4';
import Loading from '../pages/loading.f7.html?v=4';
import Encerrar from '../pages/encerrar.f7.html?v=4';
import Sejauma from '../pages/sejauma.f7.html?v=4';
import Localize from '../pages/localize.f7.html?v=4';
import NotFoundPage from '../pages/404.f7.html?v=4';
import Historico from '../pages/historico.f7.html?v=4';
import Descontar from '../pages/descontar.f7.html?v=4';
import Pesquisar from '../pages/pesquisar.f7.html?v=4';
import Descontos from '../pages/solicita_desconto.f7.html?v=4';
import Recuperar from '../pages/recuperar_senha.f7.html?v=4';
import RecuperarSucesso from '../pages/recuperarSucesso.f7.html?v=4';
import PedidosNovo from '../pages/pedidosnovo.f7.html?v=4';

var routes = [
  {
    path: '/pedidosnovo/',
    component: PedidosNovo,
  },
  {
    path: '/recuperar/',
    component: Recuperar,
  },
  {
    path: '/recuperar_sucesso/',
    component: RecuperarSucesso,
  },
  {
    path: '/',
    component: Loading
  },
  {
    path: '/descontos/',
    component: Descontos
  },
  {
    path: '/localize/',
    component: Localize
  },
  {
    path: '/sejauma/',
    component: Sejauma
  },
  {
    path: '/pesquisar/',
    component: Pesquisar
  },
  {
    path: '/encerrar/',
    component: Encerrar
  },
  {
    path: '/login/',
    component: Login
  },
  {
    path: '/home/',
    component: Home
  },
  {
    path: '/catalogo2/:edicao/',
    component: Catalogo
  },
  {
    path: '/grades/:edicao/:idproduto/:tipoestoque/',
    component: Grades
  },
  {
    path: '/produtos/:edicao/:tipoestoque/',
    component: Produtos
  },
  {
    path: '/margem/',
    component: Margem
  },
  {
    path: '/sacola/',
    component: Sacola
  },
  {
    path: '/pesquisa/:edicao/',
    component: Pesquisar
  },
  {
    path: '/senha/',
    component: Senha
  },
  {
    path: '/pedidos2/:sacola/',
    component: Pedidos
  },
  {
    path: '/configuracoes/',
    component: Configuracao
  },
  {
    path: '/descontar/',
    component: Descontar
  },
  {
    path: '/historico/',
    component: Historico
  },
  {
    path: '(.*)',
    component: NotFoundPage
  },
];

export default routes;