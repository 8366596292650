
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  on: {
    pageBeforeIn: function () {
      var self = this;
      self.$app.panel.close();
      self.$setState({ checked: self.$app.data.permiteVisualizarCusto !== undefined ? 'checked' : '' });
      self.$app.ultimatela = self.$app.view.main.history[self.$app.view.main.history.length - 1];
      if (document.querySelector('.panel-backdrop') !== null) {
        document.querySelector('.panel-backdrop').remove();
      }
    }
  },
  methods: {
    setSenha() {
      var self = this, dados = {};
      dados.tipo = 'duzani';
      dados.cpfcnpj = self.$app.data.cpfcnpj;
      self.$app.preloader.show();
      self.$app.request.promise.post(self.$app.data.link_webservice_sem_porta + 'GrupoDuzaniWS/ws?metodo=GetToken', dados).then(JSON.parse).then(res => {
        self.$app.preloader.hide();
        if (res === 'email_enviado') {
          self.$root.setAlert('Enviamos uma mensagem, verifique seu email.', 'top', '3000', 'lightblue', 'black');
          setTimeout(() => {
            self.$root.encerrarSessao();
          }, 3200);
          self.$app.sheet.close();
        } else if (res === 'cpf_nao_encontrado') {
          self.$root.setAlert('CPF/CNPJ não encontrado, entre em contato com o suporte', 'top', '3000', 'lightblue', 'black');
        } else if (res === 'Invalid Addresses') {
          self.$root.setAlert('Email não existe.', 'top', '3000', 'lightblue', 'black');
        } else if (res === 'usuario_tem_mais_de_um_cadastro') {
          self.$root.setAlert('Erro no cadastro, entre em contato com o suporte.', 'top', '3000', 'lightblue', 'black');
        }
      });
    },
    abrirPopover(classe) {
      var self = this;
      self.$app.popover.open(classe, false);
    },
    setVisualizarCusto: function () {
      var self = this, toggle = self.$app.toggle.get('.toggle');
      if (toggle.checked) {
        self.$app.data.permiteVisualizarCusto = true;
      } else {
        delete self.$app.data.permiteVisualizarCusto;
      }
      self.$root.setPersisteDados();
    },
    setTamanhos: function (tamanho) {
      var self = this, total_checado = document.querySelectorAll('div.chip.color-blue').length;
      document.querySelectorAll('.chip-label').forEach(function (label) {
        if (label.innerText === tamanho) {
          label.parentElement.classList.contains('color-blue') === true ? label.parentElement.classList.remove('color-blue') : label.parentElement.classList.add('color-blue');
          if (total_checado === 1) {
            label.parentElement.classList.add('color-blue');
          }
        }
      });
      self.$app.data.array_tamanhos.map(function (objeto) {
        if (objeto.tamanho === tamanho) {
          objeto.checado = objeto.checado === 'color-blue' ? '' : 'color-blue';
        }
        if (total_checado === 1) {
          objeto.checado == 'color-blue';
        }
        return objeto;
      });
      self.$root.setPersisteDados();
    }
  },
  id: '4ad99c7647',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n  <div class="page no-swipeback" data-name="configuracoes">\r\n    \r\n    <div class="navbar">\r\n      <div class="navbar-inner sliding">\r\n        <div class="left">\r\n          <a href="/home/" class="link" data-animate="false">\r\n            <i class="icon icon-back text-color-icone"></i>\r\n            <span class="if-not-md text-color-texto">Voltar</span>\r\n          </a>\r\n        </div>\r\n        <div class="title text-color-texto">Configuração</div>\r\n      </div>\r\n    </div>\r\n\r\n    <div class="page-content bg-color-white">\r\n\r\n      ';
      r += Template7Helpers.js_if.call(ctx_1, '../$root.resposta == \'true\'', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n      \r\n      <div class="list no-margin margin-top-half">\r\n        <ul>\r\n          <li>\r\n            <div class="item-content">\r\n              <div class="item-media">\r\n                <i class="icon f7-icons text-color-icone">money_dollar_circle_fill</i>\r\n              </div>\r\n              <div class="item-inner">\r\n                <div class="item-title">\r\n                  <a href="/margem/" data-animate="false" class="item-link text-color-black">Alterar Margem</a>\r\n                </div>\r\n              </div>\r\n            </div>                  \r\n          </li>\r\n\r\n          <li>\r\n            <div class="item-content">\r\n              <div class="item-media">\r\n                <i class="icon f7-icons text-color-icone">lock_circle_fill</i>\r\n              </div>\r\n              <div class="item-inner">\r\n                <div class="item-title">\r\n                  <a href="#" data-animate="false" class="item-link text-color-black" @click="setSenha()">Redefinir Senha</a>\r\n                </div>\r\n              </div>\r\n            </div>\r\n          </li>\r\n        </ul>\r\n      </div>\r\n      \r\n      <div class="block-title">\r\n        <div class="row">\r\n          <div class="col-50">Visualizar preço de custo</div>\r\n          <div class="col-50 text-align-right"><i class="icon material-icons text-color-icone" @click="abrirPopover(\'.popover-custo)">info</i></div>\r\n        </div>\r\n      </div>\r\n\r\n      <div class="list simple-list no-margin">\r\n        <ul>\r\n          <li>\r\n            <span>Custo</span>\r\n            <label class="toggle toggle-init color-green">\r\n              <input type="checkbox" ';
          r += c(ctx_2.checked, ctx_2);
          r += ' @change="setVisualizarCusto()">\r\n              <span class="toggle-icon"></span>\r\n            </label>\r\n          </li>\r\n        </ul>\r\n      </div>\r\n\r\n      <div class="block-title">\r\n        <div class="row">\r\n          <div class="col-50">Tamanhos permitidos</div>\r\n          <div class="col-50 text-align-right"><i class="icon material-icons text-color-icone" @click="abrirPopover(\'.popover-tamanhos\')">info</i></div>\r\n        </div>\r\n      </div>\r\n      \r\n      <div class="row margin">\r\n        <div class="col">\r\n          ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.$root.array_tamanhos, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n          <div class="chip link padding margin-half ';
              r += c(ctx_3.checado, ctx_3);
              r += '" @click="setTamanhos(\'';
              r += c(ctx_3.tamanho, ctx_3);
              r += '\')">\r\n            <div class="chip-label">';
              r += c(ctx_3.tamanho, ctx_3);
              r += '</div>\r\n          </div>\r\n          ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n        </div>\r\n      </div>\r\n      \r\n      <div class="popover popover-custo">\r\n        <div class="popover-inner">\r\n          <div class="block">\r\n            <p>Ativando esta função, será permitido visualizar o preço de custo nas telas de produto e grade. Nelas, após ativar esta função, você poderá arrastar o dedo para o lado esquerdo em cima do produto para aparecer o valor de custo. Essa função serve para se consultar rapidamente no meio de uma negociação de pedido de catálogo.</p>\r\n          </div>\r\n        </div>\r\n      </div>\r\n\r\n      <div class="popover popover-tamanhos">\r\n        <div class="popover-inner">\r\n          <div class="block">\r\n            <p>Ativando esta função, o aplicativo filtrará em todas as telas que permite seleção de tamanhos pegando somente os tamanhos selecionados na lista. Essa função serve para que você pré configure seu app para atender determinada cliente ou grupo de clientes.<br><b>Pelo menos um tamanho deverá ser selecionado.</b></p>\r\n          </div>\r\n        </div>\r\n      </div>\r\n      ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n\r\n    </div>\r\n\r\n  </div>\r\n';
      return r;
    }(this);
  },
  style: `
  .dialog-button {
    color: #282a36 !important;
  }
  .dialog-buttons > span:nth-child(1) {
    display: none
  }
`,
  styleScoped: false
};
    