
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return { pedidos: [] };
  },
  on: {
    pageInit: function () {
      var self = this;
      self.$app.panel.close();
      self.getPedidos();
    }
  },
  methods: {
    getPedidos() {
      var self = this;
      self.$app.preloader.show();
      self.$app.request.promise.post(self.$app.data.link_webservice_sem_porta + 'GrupoDuzaniWS/ws?metodo=GetPedidosDuzani', { idpessoa: self.$app.data.idpessoa }).then(JSON.parse).then(function (dados) {
        self.$app.preloader.hide();
        self.$setState({
          pedidos: dados.map(m => {
            m.data = m.data.getData();
            m.valortotal = Number(m.valortotal).getMoeda();
            return m;
          })
        });
      });
    },
    setAbrirInformacaoPedido(idpedidovenda, status) {
      var self = this;
      self.$app.popup.close();
      self.$app.popup.open('.popup-pedido-info', false);
      self.$setState({ status: status });
      self.GetPedidoItem(idpedidovenda, status);
    },
    GetPedidoItem(idpedidovenda, status) {
      var self = this;
      self.$app.preloader.show();
      self.$app.request.promise.post(self.$app.data.link_webservice_sem_porta + 'GrupoDuzaniWS/ws?metodo=GetPedidosDuzaniItens', {
        idpedidovenda,
        status
      }).then(JSON.parse).then(function (dados) {
        self.$app.preloader.hide();
        self.setPedidosItens(dados);
      });
    },
    setPedidosItens(pedidositens) {
      let self = this;
      self.$setState({
        idpedidovenda: pedidositens[0].idpedidovenda,
        tipoestoque: pedidositens[0].tipoestoque,
        pedidositens: self.formataPedidosItens(pedidositens)
      });
      self.$setState({ data: self.pedidositens[0].data });
    },
    formataPedidosItens(pedidositens) {
      var self = this;
      return pedidositens.map(m => {
        m.data = m.data.getData();
        m.valortotal = Number(m.valortotal).getMoeda();
        m.fotosistema = self.$app.data.link_webservice + '/' + m.fotosistema;
        return m;
      });
    }
  },
  id: 'cac7a1d931',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n  <div class="page no-swipeback" data-name="pedidosnovo">\r\n    <div class="navbar">\r\n      <div class="navbar-inner sliding">\r\n        <div class="left">\r\n          <a href="/home/" class="link" data-animate="false">\r\n            <i class="icon icon-back text-color-icone"></i>\r\n            <span class="if-not-md text-color-texto">Voltar</span>\r\n          </a>\r\n        </div>\r\n        <div class="title text-color-texto">Pedidos</div>\r\n      </div>\r\n    </div>\r\n    \r\n    <div class="page-content bg-color-neve" style="overflow: auto;">\r\n\r\n      ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.pedidos, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n        <div class="card padding-bottom padding-left padding-right padding-top">\r\n          <h3 class="text-color-marrom border-bottom no-margin">Pedido #';
          r += c(ctx_2.idpedidovenda, ctx_2);
          r += '</h3>\r\n          <p><span style="font-weight: bold;">Data:</span> ';
          r += c(ctx_2.data, ctx_2);
          r += '</p>\r\n          <p><span style="font-weight: bold;">Status:</span> ';
          r += c(ctx_2.status, ctx_2);
          r += '</p>\r\n          <p><span style="font-weight: bold;">Estoque:</span> ';
          r += c(ctx_2.tipoestoque, ctx_2);
          r += '</p>\r\n          <p><span style="font-weight: bold;">Total:</span> ';
          r += c(ctx_2.valortotal, ctx_2);
          r += '</p>\r\n          <button class="button button-small button-fill color-blue" @click="setAbrirInformacaoPedido(';
          r += c(ctx_2.idpedidovenda, ctx_2);
          r += ', \'';
          r += c(ctx_2.status, ctx_2);
          r += '\')">Visualizar</button>\r\n        </div>\r\n      ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n\r\n    </div>\r\n\r\n    <!-- POPUP INFORMAÇÕES DO PEDIDO -->\r\n    <div class="popup popup-pedido-info bg-color-neve">\r\n\r\n      <div class="navbar">\r\n        <div class="navbar-bg bg-color-marrom"></div>\r\n        <div class="navbar-inner sliding">\r\n          <div class="left">\r\n            <a href="#" class="link popup-close">\r\n              <i class="icon icon-back text-color-icone"></i>\r\n              <span class="if-not-md text-color-texto">Voltar</span>\r\n            </a>\r\n          </div>\r\n          <div class="title text-color-black">Informações do Pedido</div>\r\n        </div>\r\n      </div>\r\n      \r\n      <div class="page-content no-padding padding-bottom-rodape">\r\n        <div class="card padding-bottom padding-left padding-right padding-top">\r\n          <h3 class="font-20 text-color-marrom border-bottom no-margin">Pedido ';
      r += c(ctx_1.status, ctx_1);
      r += '</h3>\r\n          <p><span style="font-weight: bold;">ID:</span> #';
      r += c(ctx_1.idpedidovenda, ctx_1);
      r += '</p>\r\n          <p><span style="font-weight: bold;">Data:</span> ';
      r += c(ctx_1.data, ctx_1);
      r += '</p>\r\n          <p><span style="font-weight: bold;">Estoque:</span> ';
      r += c(ctx_1.tipoestoque, ctx_1);
      r += '</p>\r\n        </div>\r\n\r\n        <div class="card padding-bottom padding-left padding-right padding-top">\r\n          <h3 class="font-20 text-color-marrom border-bottom no-margin">Produtos</h3>\r\n          \r\n          ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.pedidositens, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n            <div class="margin-top">\r\n              \r\n              <div class="row border-bottom">\r\n                <div class="col-20">\r\n                  <img src="';
          r += c(ctx_2.fotosistema, ctx_2);
          r += '" class="foto-sistema-produto">\r\n                </div>\r\n                <div class="col-80">\r\n                  <p class="no-margin">';
          r += c(ctx_2.descricao, ctx_2);
          r += '</p>\r\n\r\n                  <div class="row">\r\n                    <div class="col-50">\r\n                      <p style="font-weight: bold;">';
          r += c(ctx_2.quantidade, ctx_2);
          r += 'un.</p>\r\n                    </div>\r\n                    <div class="col-50">\r\n                      <p class="text-align-right" style="font-weight: bold;">';
          r += c(ctx_2.valortotal, ctx_2);
          r += '</p>\r\n                    </div>\r\n                  </div>\r\n                </div>\r\n              </div>              \r\n              \r\n            </div>\r\n          ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n';
      return r;
    }(this);
  },
  style: `
  .foto-sistema-produto {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border-color: #bdbdbd;
    border-style: solid;
  }
  .padding-bottom-rodape {
    padding-bottom: 100px !important;
  }
`,
  styleScoped: false
};
    