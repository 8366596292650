
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    var self = this;
    var tela = '/catalogo2/' + self.$route.params.edicao + '/';
    return {
      swipeout: self.$app.data.permiteVisualizarCusto !== undefined ? 'swipeout' : '',
      mostrar_sacola: '',
      loading: true,
      telaanterior: tela,
      tipoestoque: self.$route.params.tipoestoque
    };
  },
  on: {
    pageBeforeIn: function (e, page) {
      var self = this;
      var total_sacola = self.$root.getQuantidadeTotalSacola();
      self.$setState({
        mostrar_sacola: total_sacola > 0 ? 'sim' : '',
        total_sacola: total_sacola
      });
      var promessa = '';
      if (self.$route.params.tipoestoque === 'fabrica') {
        promessa = self.$root.retornaParametros(Number(self.$route.params.edicao), self.$app.data.edicaoPagina.find(function (o) {
          return o.edicao == self.$route.params.edicao;
        }).numpagina);
      } else {
        promessa = self.$root.retornaGradesProprios(Number(self.$route.params.edicao), self.$app.data.edicaoPagina.find(function (o) {
          return o.edicao == self.$route.params.edicao;
        }).numpagina, 'nao');
      }
      promessa.then(function (estoquecatalogoatual) {
        if (self.$app) {
          self.$app.data.estoquecatalogoatual = estoquecatalogoatual.map(function (reg) {
            if (self.$root.getLocalhost() === true) {
              reg.link = 'https://sistemaagely.com.br:8145/' + self.$app.data.versao_fallback + '/' + reg.link;
            } else {
              reg.link = self.$app.data.link_webservice + '/' + reg.link;
            }
            reg.preco = reg.preco;
            reg.precoBR = 'R$ ' + self.$root.formataMoeda(self.$root.setPrecoMargem(reg.preco));
            reg.custoBR = 'R$ ' + self.$root.formataMoeda(reg.preco);
            return reg;
          });
          self.init();
        }
      });
    }
  },
  methods: {
    init: function () {
      var self = this;
      self.dadosProdutos = self.$app.data.estoquecatalogoatual.filter(function (o) {
        return o.numpagina === self.$app.data.edicaoPagina.find(function (o) {
          return o.edicao == self.$route.params.edicao;
        }).numpagina;
      });
      self.$setState({
        loading: false,
        linhas: self.dadosProdutos.map(function (dado) {
          return dado.idproduto;
        }).reduce((a, d) => {
          if (!a.includes(d)) {
            a.push(d);
          }
          return a;
        }, []).map(function (idproduto) {
          return self.dadosProdutos.find(function (dado) {
            return dado.idproduto === idproduto;
          });
        }).map(function (reg) {
          return {
            idproduto: reg.idproduto,
            descricao: reg.descricao,
            idprodutograde: reg.idprodutograde,
            custoBR: reg.custoBR,
            idprodutosubsecao: reg.idprodutosubsecao,
            idmarca: reg.idmarca,
            idtabelapreco: self.$app.data.idtabelapreco,
            edicao: self.$route.params.edicao,
            referencia: reg.ctrl_conjunto,
            valor: reg.precoBR,
            valorsemtab: reg.precocusto,
            fotos: self.dadosProdutos.filter(function (o) {
              return o.idproduto === reg.idproduto;
            }).map(function (o) {
              return o.link;
            }).reduce((a, d) => {
              if (!a.includes(d)) {
                a.push(d);
              }
              return a;
            }, [])
          };
        })
      });
    },
    abrirFotos: function (foto) {
      var self = this;
      self.$app.photoBrowser.create({
        photos: self.linhas.filter(function (e) {
          return e.fotos.find(function (f) {
            return f === foto;
          });
        })[0].fotos,
        lazy: { enabled: true },
        theme: 'dark'
      }).open();
    }
  },
  id: '2e8f9aa84b',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n  <div class="page no-swipeback" data-name="produtos">\r\n    <div class="navbar">\r\n      <div class="navbar-inner sliding">\r\n        <div class="left">\r\n          <a href="';
      r += c(ctx_1.telaanterior, ctx_1);
      r += '" class="link" data-animate="false">\r\n            <i class="icon icon-back text-color-icone"></i>\r\n            <span class="if-not-md text-color-texto">Voltar</span>\r\n          </a>\r\n        </div>\r\n        <div class="title text-color-texto">Produtos</div>\r\n        <div class="right">\r\n          ';
      r += Template7Helpers.js_if.call(ctx_1, '../$root.resposta == \'true\'', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n          <a href="/sacola/" data-animate="false" class="link icon-only">\r\n            ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.mostrar_sacola === \'sim\'', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n              <i class="icon f7-icons if-ios text-color-icone">bag<span class="badge color-blue">';
              r += c(ctx_3.total_sacola, ctx_3);
              r += '</span></i>\r\n              <i class="icon material-icons if-md text-color-icone">shopping_basket<span class="badge color-blue">';
              r += c(ctx_3.total_sacola, ctx_3);
              r += '</span></i>\r\n            ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n              <i class="icon f7-icons if-ios text-color-icone">bag</i>\r\n              <i class="icon material-icons if-md text-color-icone">shopping_basket</i>\r\n            ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n          </a>\r\n          ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <div class="page-content bg-color-white">\r\n      \r\n      <div class="row">\r\n        <div class="col-auto tablet-15"></div>\r\n        <div class="col-auto tablet-70">\r\n          <div class="block no-margin no-padding">\r\n            <div class="list no-hairlines no-margin">\r\n              <ul>\r\n\r\n                ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.loading, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '                  \r\n                <li class="item-link item-content skeleton-text skeleton-effect-blink">\r\n                  <div class="item-content width-100">\r\n                    <div class="item-media">\r\n                      <div class="skeleton-block" style="width: 40px; height: 40px; border-radius: 50%"></div>\r\n                    </div>\r\n                    <a class="width-100 padding-left" href="#">\r\n                      <div class="item-inner">\r\n                        ';
          r += Template7Helpers.js_if.call(ctx_2, '../$root.resposta == \'true\'', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                        <div class="item-title text-color-texto">\r\n                          333333\r\n                        </div>\r\n                        <div class="item-after">Ref.:<b>333333</b></div>\r\n                        ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                        <div class="item-title text-color-texto">\r\n                          333333\r\n                        </div>\r\n                        ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                      </div>\r\n                    </a>\r\n                  </div>\r\n                </li>                  \r\n                ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                  ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.linhas, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '            \r\n                  <li class="item-link item-content ';
              r += c(root.swipeout, ctx_3);
              r += '">\r\n                    <div class="item-content swipeout-content width-100">\r\n                      <div class="item-media">\r\n                      ';
              r += Template7Helpers.each.call(ctx_3, ctx_3.fotos, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n                        <img src="';
                  r += c(ctx_4, ctx_4);
                  r += '" class="foto-sistema" />\r\n                      ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\r\n                      </div>\r\n                      <a class="width-100 padding-left" href="/grades/';
              r += c(ctx_3.edicao, ctx_3);
              r += '/';
              r += c(ctx_3.idproduto, ctx_3);
              r += '/';
              r += c(root.tipoestoque, ctx_3);
              r += '/" data-animate="false">\r\n                        <div class="item-inner">\r\n                          ';
              r += Template7Helpers.js_if.call(ctx_3, '../$root.resposta == \'true\'', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n                          <div class="item-title text-color-texto">\r\n                            ';
                  r += c(ctx_4.valor, ctx_4);
                  r += '\r\n                          </div>\r\n                          <div class="item-after">Ref.:<b>';
                  r += c(ctx_4.referencia, ctx_4);
                  r += '</b></div>\r\n                          ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n                          <div class="item-title text-color-texto">\r\n                            Ref.:';
                  r += c(ctx_4.referencia, ctx_4);
                  r += '\r\n                          </div>\r\n                          ';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\r\n                        </div>\r\n                      </a>\r\n                    </div>\r\n\r\n                    <div class="swipeout-actions-right">\r\n                      <a href="#" class="text-color-white bg-color-dourado">';
              r += c(ctx_3.custoBR, ctx_3);
              r += '</a>\r\n                    </div>\r\n                  </li>            \r\n                  ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n              </ul>\r\n            </div>\r\n          </div>\r\n        </div>\r\n        <div class="col-auto tablet-15"></div>\r\n      </div>\r\n\r\n    </div>\r\n  </div>\r\n';
      return r;
    }(this);
  },
  styleScoped: false
};
    