
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    var self = this;
    return {
      edicao: self.$route.params.edicao,
      link_compartilhar: self.$app.data.link_s3 + 'catalogos/catalogo' + self.$route.params.edicao + '/hd/capa.jpg',
      mostrar_sacola: '',
      loading: true,
      hack_data: new Date()
    };
  },
  on: {
    pageBeforeOut: function (e, page) {
      var self = this;
    },
    pageBeforeIn: function (e, page) {
      var self = this, paginas = [], total_sacola = self.$root.getQuantidadeTotalSacola();
      self.$setState({
        mostrar_sacola: total_sacola > 0 ? 'sim' : '',
        total_sacola: total_sacola
      });
      self.iniCarregamento();
    }
  },
  methods: {
    ativarHD() {
      var self = this, imagem = document.querySelector('#img' + self.swiperFotos.activeIndex), botao = document.querySelector('#botao' + self.swiperFotos.activeIndex);
      if (botao.classList.contains('button-outline')) {
        botao.classList.remove('button-outline');
        botao.classList.add('button-fill');
        botao.classList.remove('text-color-gray');
        botao.classList.add('text-color-white');
        botao.classList.remove('color-gray');
        botao.classList.add('color-black');
        imagem.src = self.$app.data.link_s3 + 'catalogos/catalogo' + self.edicao + '/hd/' + self.paginas[self.swiperFotos.activeIndex].numpagina + '.jpg';
      } else {
        botao.classList.remove('button-fill');
        botao.classList.add('button-outline');
        botao.classList.remove('text-color-white');
        botao.classList.add('text-color-gray');
        botao.classList.remove('color-black');
        botao.classList.add('color-gray');
        imagem.src = self.$app.data.link_s3 + 'catalogos/catalogo' + self.edicao + '/mobile/' + self.paginas[self.swiperFotos.activeIndex].numpagina + '.jpg';
      }
    },
    iniCarregamento: function () {
      var self = this;
      self.carregaPaginas();
    },
    carregaPaginas: function () {
      var self = this;
      self.$root.carregaFotosCatalogo(Number(self.$route.params.edicao)).then(function (dados) {
        return dados.arrayMobileCatalogoObjeto;
      }).then(function (paginas) {
        self.$root.retornaParametros(Number(self.$route.params.edicao), false).then(function (estoquecatalogoatual) {
          self.$setState({
            loading: false,
            paginas: self.$root.normalizaPaginas(paginas, self.$route.params.edicao).map(function (pagina) {
              var saldo_pagina = 0;
              var produtos_pagina = estoquecatalogoatual.filter(f => f.numpagina === pagina.numpagina).filter(f => f.estoque > 0).unico({ prop: 'ctrl_conjunto' }).map(m => m.ctrl_conjunto);
              pagina.produtos_pagina = produtos_pagina;
              saldo_pagina += estoquecatalogoatual.filter(f => f.numpagina === pagina.numpagina).sum({ prop: 'estoque' });
              pagina.tipoestoque = 'fabrica';
              pagina.tem_estoque = saldo_pagina > 0 ? true : false;
              return pagina;
            })
          });
          self.iniSwiperFotos();
        });
      });
    },
    iniSwiperFotos: function () {
      var self = this;
      if (self.swiperFotos !== undefined) {
        self.swiperFotos.destroy();
      }
      self.swiperFotos = new self.$app.swiper.create('.swiper-fotos', {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        lazy: {
          loadPrevNext: true,
          loadPrevNextAmount: 5
        },
        zoom: { maxRatio: 3 },
        on: {
          slideChangeTransitionStart: function () {
            self.$setState({ link_compartilhar: self.$app.data.link_s3 + 'catalogos/catalogo' + self.edicao + '/hd/' + self.paginas[self.swiperFotos.activeIndex].numpagina + '.jpg' });
            self.$root.memorizaPagina(self.edicao, self.swiperFotos.activeIndex, self.paginas[self.swiperFotos.activeIndex].numpagina);
            console.log(self.paginas[self.swiperFotos.activeIndex]);
          }
        }
      });
      if (self.$app.data.edicaoPagina !== undefined) {
        var indexMemorizado, objetoMemorizado = self.$app.data.edicaoPagina.find(function (o) {
            return o.edicao === self.edicao;
          });
        if (objetoMemorizado !== undefined) {
          indexMemorizado = objetoMemorizado.index;
          self.swiperFotos.slideTo(indexMemorizado, 0);
        }
      }
    },
    vaiParaACapa: function () {
      var self = this;
      self.swiperFotos.slideTo(0, 0);
    },
    compartilhar: function () {
      var self = this;
      self.toastCompartilhar = self.$app.toast.create({
        text: 'compartilhando...',
        position: 'bottom',
        closeTimeout: 0
      }).open();
      if (window.plugins !== undefined) {
        window.plugins.socialsharing.share(null, null, self.link_compartilhar, null, function (result) {
          self.toastCompartilhar.close();
        }, function (result) {
          self.toastCompartilhar.close();
        });
      }
      setTimeout(function () {
        self.toastCompartilhar.close();
      }, 4000);
    }
  },
  id: '504437a87c',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n  <div class="page no-swipeback" data-name="catalogo">\r\n    <div class="navbar">\r\n      <div class="navbar-inner sliding">\r\n        <div class="left">\r\n          <a href="/home/" class="link" data-animate="false">\r\n            <i class="icon icon-back text-color-icone"></i>\r\n            <span class="if-not-md text-color-texto">Voltar</span>\r\n          </a>\r\n        </div>\r\n        <div class="right">\r\n          <a href="#" class="link icon-only" @click="vaiParaACapa()">\r\n            <i class="icon f7-icons if-ios text-color-icone">house</i>\r\n            <i class="icon material-icons if-md text-color-icone">home</i>\r\n          </a>\r\n          \r\n          ';
      r += Template7Helpers.js_if.call(ctx_1, '../$root.resposta == \'true\'', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n            <a href="/pesquisa/';
          r += c(ctx_2.edicao, ctx_2);
          r += '/" data-animate="false" class="link icon-only">\r\n              <i class="icon material-icons text-color-icone">search</i>\r\n            </a>\r\n          ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n          \r\n          <a href="#" class="link icon-only" @click="compartilhar()">\r\n            <i class="icon f7-icons if-ios text-color-icone">square_arrow_up</i>\r\n            <i class="icon material-icons if-md text-color-icone">share</i>\r\n          </a>\r\n          ';
      r += Template7Helpers.js_if.call(ctx_1, '../$root.resposta == \'true\'', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n          <a href="/sacola/" data-animate="false" class="link icon-only">\r\n            ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.mostrar_sacola === \'sim\'', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n              <i class="icon f7-icons if-ios text-color-icone">bag<span class="badge color-blue">';
              r += c(ctx_3.total_sacola, ctx_3);
              r += '</span></i>\r\n              <i class="icon material-icons if-md text-color-icone">shopping_basket<span class="badge color-blue">';
              r += c(ctx_3.total_sacola, ctx_3);
              r += '</span></i>\r\n            ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n              <i class="icon f7-icons if-ios text-color-icone">bag</i>\r\n              <i class="icon material-icons if-md text-color-icone">shopping_basket</i>\r\n            ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n          </a>\r\n          ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <div class="page-content bg-color-white">\r\n      \r\n      <div class="row">\r\n        <div class="col-auto tablet-30">\r\n          <div class="swiper-button-prev padding text-color-texto outline"></div>\r\n        </div>\r\n        <div class="col-auto tablet-40">\r\n          <div class="swiper-container swiper-container-horizontal swiper-fotos">\r\n            <div class="swiper-wrapper">\r\n              ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.loading, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                <div class="row skeleton-block skeleton-effect-blink" style="height: 80vh">\r\n                  <div class="col-100 tablet-30"></div>\r\n                  <div class="col-100 tablet-40 align-self-center">\r\n                    <p class="text-align-center"><i class="icon f7-icons text-color-icone">photo_fill</i></p>\r\n                  </div>\r\n                  <div class="col-100 tablet-30"></div>\r\n                </div>\r\n              ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.paginas, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                <div class="swiper-slide text-align-center">\r\n\r\n                  <!-- <i class="icon f7-icons color-blue icon-tooltip">info_fill</i> <i class="icon material-icons color-blue icon-tooltip">info</i> -->\r\n                  <!-- <div class="bg-color-black mytooltip button text-color-white">\r\n                    <p class="no-margin">23308 - Conjunto Push Up. Calcinha fio</p>\r\n                    <i class="icon f7-icons color-white">chevron_right</i>\r\n                  </div> -->\r\n\r\n                  <button id="botao';
              r += c(data_3 && data_3.index, ctx_3);
              r += '" class="button button-outline color-gray text-color-gray" @click="ativarHD()" style="position: absolute;z-index: 9;width: 44px;right: 10px;top: 10px;">HD</button>\r\n                  \r\n                  <div class="swiper-zoom-container">\r\n                    <img id="img';
              r += c(data_3 && data_3.index, ctx_3);
              r += '" src="';
              r += c(ctx_2.$root.link_s3, ctx_3);
              r += 'catalogos/catalogo';
              r += c(ctx_3.edicao, ctx_3);
              r += '/low/';
              r += c(ctx_3.numpagina, ctx_3);
              r += '.jpg" data-src="';
              r += c(ctx_2.$root.link_s3, ctx_3);
              r += 'catalogos/catalogo';
              r += c(ctx_3.edicao, ctx_3);
              r += '/mobile/';
              r += c(ctx_3.numpagina, ctx_3);
              r += '.jpg?';
              r += c(root.hack_data, ctx_3);
              r += '" width="100%" class="swiper-lazy" />\r\n                    <div class="swiper-lazy-preloader"></div>\r\n                  </div>\r\n                  \r\n                  ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.idproduto > 0 && this.tem_estoque == true', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n                    <!-- fab fab-extended fab-right-bottom -->\r\n                    <div class="toolbar toolbar-bottom bg-color-green">\r\n                      <a class="font-montserrat link text-color-white color-green" href="/produtos/';
                  r += c(ctx_4.edicao, ctx_4);
                  r += '/';
                  r += c(ctx_4.tipoestoque, ctx_4);
                  r += '/" data-animate="false">                        \r\n                        PEDIR <i class="icon f7-icons" style="position: relative;right: 0px;">chevron_right</i>\r\n                      </a>\r\n                    </div>\r\n\r\n                  ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\r\n\r\n                  ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.idproduto > 0 && this.tem_estoque == false', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n                  <div class="div_esgotado">\r\n                    <div class="div_esgotado_inner">                      \r\n                      <div class="div_esgotado_texto">esgotado</div>                      \r\n                    </div>\r\n                  </div>\r\n                  ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\r\n\r\n                </div>\r\n                ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n              ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n            </div>\r\n          </div>\r\n        </div>\r\n        <div class="col-auto tablet-30">\r\n          <div class="swiper-button-next padding text-color-texto outline"></div>\r\n        </div>\r\n      </div>\r\n\r\n    </div>\r\n\r\n  </div>\r\n';
      return r;
    }(this);
  },
  style: `
  .div_esgotado {
    position: absolute;
    bottom: 0px;
    width: 95vw;
    margin: 10px;
    background: #636363;
    border-radius: 6px;
  }
  .div_esgotado_inner {
    width: 100%;
    padding: 10px;
  }
  .div_esgotado_texto {
    width: 100%;
    text-align: left;
    color: white;
  }
`,
  styleScoped: false
};
    