export default {
  on: {
    pageInit() {
      let self = this;
      self.$setState({ lageli_logo: self.$root.lageli_logo });
    }
  },
  id: 'bc11e8aa55',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n  <div class="page theme-dark" data-name="recuperar_sucesso">\r\n    <div class="page-content margin page-center-center">\r\n      <div class="block">\r\n        \r\n        <div class="row">\r\n          <div class="col-100 xsmall-5 small-10 medium-30 large-30 xlarge-30"></div>\r\n          <div class="col-100 xsmall-90 small-80 medium-40 large-40 xlarge-40">\r\n            \r\n            <img class="width-100" src="';
      r += c(ctx_1.lageli_logo, ctx_1);
      r += '">\r\n            <p class="text-align-center font-montserrat-bold">Senha alterada com sucesso! Volte no App e faça seu login.</p>\r\n          </div>\r\n          <div class="col-100 xsmall-5 small-10 medium-30 large-30 xlarge-30"></div>\r\n        </div>\r\n        \r\n      </div>\r\n    </div>\r\n  </div>\r\n';
      return r;
    }(this);
  },
  styleScoped: false
};