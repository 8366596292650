
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  on: {
    pageBeforeIn: function () {
      var self = this;
      self.$app.panel.close();
      self.init();
      setTimeout(() => {
        if (self.$app.data.resposta !== undefined) {
          self.$app.view.main.router.navigate('/home/', { animate: false });
        }
      }, 0);
      if (document.querySelector('.panel-backdrop') !== null) {
        document.querySelector('.panel-backdrop').remove();
      }
    }
  },
  methods: {
    visitante() {
      var self = this;
      self.$app.view.main.router.navigate('/home/', {
        animate: false,
        reloadAll: true,
        ignoreCache: true
      });
    },
    init: function () {
      var self = this;
      self.$setState({
        link_catalogo: self.$app.data.link_catalogo,
        link_chat: self.$app.data.link_chat
      });
      self.$app.lazy.loadImage('#logoDuzani');
      self.$app.lazy.loadImage('#logoWhatsapp');
      document.querySelectorAll('.dialog-backdrop').forEach(function (e) {
        e.remove();
      });
      document.querySelectorAll('.dialog-no-buttons').forEach(function (e) {
        e.remove();
      });
      if (self.$app.data.dialog_internet !== undefined) {
        var interval_internet_login = setInterval(function () {
          if (self.$app.data.dialog_internet === undefined) {
            self.init();
            clearTimeout(interval_internet_login);
          }
        }, 1000);
      }
    },
    getToken() {
      let self = this, dados = {}, email = '';
      self.$app.dialog.prompt('Informe seu email', '', function (_email) {
        self.$app.preloader.show();
        email = _email;
        self.$app.request.promise.post(self.$app.data.link_webservice_sem_porta + 'GrupoDuzaniWS/ws?metodo=GetLogin', { email: email }).then(JSON.parse).then(res => {
          self.$app.preloader.hide();
          if (res.resposta === 'true') {
            var campoid = 'CPF/CNPJ';
            if (res.estrangeiro === 't') {
              campoid = 'ID';
            }
            self.$app.dialog.prompt('Informe seu ' + campoid, '', function (cpfcnpj) {
              dados.tipo = 'duzani';
              dados.cpfcnpj = cpfcnpj.replaceAll('.', '').replace('-', '').replace('/', '');
              self.$app.preloader.show();
              self.$app.request.promise.post(self.$app.data.link_webservice_sem_porta + 'GrupoDuzaniWS/ws?metodo=GetToken', dados).then(JSON.parse).then(res => {
                self.$app.preloader.hide();
                if (res === 'email_enviado') {
                  self.$root.setAlert('Enviamos uma mensagem, verifique seu email.', 'top', '3000', 'lightblue', 'black');
                  self.$app.sheet.close();
                } else if (res === 'cpf_nao_encontrado') {
                  self.$root.setAlert(campoid + ' não encontrado, entre em contato com o suporte', 'top', '3000', 'lightblue', 'black');
                } else if (res === 'Invalid Addresses') {
                  self.$root.setAlert('Email não existe.', 'top', '3000', 'lightblue', 'black');
                } else if (res === 'usuario_tem_mais_de_um_cadastro') {
                  self.$root.setAlert('Erro no cadastro, entre em contato com o suporte.', 'top', '3000', 'lightblue', 'black');
                }
              });
            });
            if (res.estrangeiro === 'f') {
              self.$root.setFormataCampo('mask', '.dialog-input', '.dialog-text', 'CPF', [
                { mask: '000.000.000-00' },
                { mask: '00.000.000/0000-00' }
              ]);
              self.$root.setFormataCampo('atributo', '.dialog-input', '.dialog-text', 'Informe seu CPF/CNPJ', 'tel');
            }
            setTimeout(() => {
              document.querySelector('.dialog-inner').classList.add('bg-color-rosa');
              document.querySelectorAll('.dialog-button')[0].classList.add('bg-color-rosa');
              document.querySelectorAll('.dialog-button')[1].classList.add('bg-color-rosa');
              document.querySelectorAll('.dialog-button')[0].classList.add('text-color-black');
              document.querySelectorAll('.dialog-button')[1].classList.add('text-color-black');
            }, 500);
          } else {
            self.$root.setAlert('Email não cadastrado.', 'top', '3000', 'lightblue', 'black');
          }
        });
      });
      self.$root.setFormataCampo('atributo', '.dialog-input', '.dialog-text', 'Email', 'email');
      document.querySelector('.dialog-inner').classList.add('bg-color-rosa');
      document.querySelectorAll('.dialog-button')[0].classList.add('bg-color-rosa');
      document.querySelectorAll('.dialog-button')[1].classList.add('bg-color-rosa');
      document.querySelectorAll('.dialog-button')[0].classList.add('text-color-black');
      document.querySelectorAll('.dialog-button')[1].classList.add('text-color-black');
    },
    login: function () {
      var self = this, email = document.querySelector('#newtxtemail').value, senha = document.querySelector('#newtxtsenha').value;
      if (email.length > 0 && senha.length > 0) {
        self.toastLogin = self.$app.toast.create({
          text: 'entrando...',
          position: 'center',
          closeTimeout: 0
        }).open();
        self.$root.login(email, senha).then(JSON.parse).then(function (dadosaux) {
          self.toastLogin.close();
          if (dadosaux.resposta === 'true' && dadosaux.idtabelapreco !== 'null') {
            var array_dados = [];
            array_dados.push(dadosaux);
            var dados = array_dados.map(function (prop) {
              if (self.$root.getLocalhost() === true) {
                prop.link = 'https://sistemaagely.com.br:8145/' + self.$app.data.versao_fallback + '/' + prop.link;
              } else {
                prop.link = self.$app.data.link_webservice + '/' + prop.link;
              }
              return prop;
            })[0];
            self.$app.data = Object.assign(self.$app.data, dados);
            self.$root.setPersisteDados(true);
            self.$app.view.main.router.navigate('/home/', { animate: false });
          } else {
            if (dadosaux.resposta === 'false') {
              self.$app.toast.create({
                text: 'Email ou senha incorretos',
                position: 'top',
                closeTimeout: 5000
              }).open();
            }
            if (dadosaux.idtabelapreco === 'null') {
              self.$app.toast.create({
                text: 'Sem tabela de preço',
                position: 'top',
                closeTimeout: 5000
              }).open();
            }
          }
        });
      } else {
        self.$app.toast.create({
          text: 'Preencha os dados corretamente',
          position: 'top',
          closeTimeout: 5000
        }).open();
      }
    }
  },
  id: '5556b6577d',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n  <div class="page no-swipeback" data-name="login">\r\n    \r\n    <div class="page-content login-screen-content bg-color-neve no-padding">\r\n      \r\n      <div class="row">\r\n        <div class="col-100 tablet-30 desktop-30"></div>\r\n        <div class="col-100 tablet-40 desktop-40 align-self-center">\r\n          <div class="login-screen-title">\r\n            <img id="logoDuzani" data-src="';
      r += c(ctx_1.$root.link_catalogo, ctx_1);
      r += 'assets/imgduzani/logoDuzaniVertical.png" class="lazy lazy-fade-in" />\r\n          </div>\r\n          <div class="list padding no-margin">\r\n            <ul>\r\n              <li class="item-content item-input color-preto">\r\n                <div class="item-inner">\r\n                  \r\n                  <div class="item-input-wrap">\r\n                    <input type="email" id="newtxtemail" name="newtxtemail" placeholder="e-mail">\r\n                  </div>\r\n                </div>\r\n              </li>\r\n              <li class="item-content item-input color-preto">\r\n                <div class="item-inner">\r\n                  \r\n                  <div class="item-input-wrap">\r\n                    <input type="password" id="newtxtsenha" name="newtxtsenha" placeholder="Senha">\r\n                  </div>\r\n                </div>\r\n              </li>\r\n            </ul>\r\n          </div>\r\n          <div class="list width-100 no-margin">\r\n            \r\n            <a href="#" class="bg-color-dourado button button-fill button-large text-color-white item-link margin" @click="login()">ENTRAR</a>\r\n    \r\n            <div class="block-footer padding-top">\r\n    \r\n              <a class="bg-color-dourado button link margin text-color-white" @click="getToken()">\r\n                <div class="row">\r\n                  <i class="col-20 icon f7-icons text-color-white if-md padding-top-halfinho">envelope</i>\r\n                  <i class="col-20 icon f7-icons text-color-white if-ios padding-top-halfinho-ios">envelope</i>\r\n                  <span class="col-80">Esqueceu sua senha?</span>\r\n                </div>\r\n              </a>\r\n              <a href="';
      r += c(ctx_1.$root.link_chat, ctx_1);
      r += '" class="text-color-white bg-color-whatsapp button external link margin" target="_system">\r\n                <div class="row">\r\n                  ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.link_catalogo != undefined', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                  <img id="logoWhatsapp" data-src="';
          r += c(ctx_2.link_catalogo, ctx_2);
          r += 'assets/imgduzani/whatsapp.svg" class="lazy lazy-fade-in" />\r\n                  ';
          r += Template7Helpers.js_if.call(ctx_2, '../$root.link_catalogo != undefined', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                    ';
          r += Template7Helpers.js_if.call(ctx_2, '../$root.link_catalogo != undefined', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                    <img id="logoWhatsapp" data-src="';
              r += c(ctx_2.$root.link_catalogo, ctx_3);
              r += 'assets/imgduzani/whatsapp.svg" class="lazy lazy-fade-in" />\r\n                    ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                  ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n                  <span class="col-80">Suporte via WhatsApp</span>\r\n                </div>\r\n              </a>\r\n            </div>\r\n\r\n            <a href="#" class="link text-color-black width-100" @click="visitante()">VISITANTE</a>\r\n          </div>\r\n        </div>\r\n        <div class="col-100 tablet-30 desktop-30"></div>\r\n      </div>\r\n\r\n    </div>\r\n\r\n  </div>\r\n';
      return r;
    }(this);
  },
  style: `
#logoDuzani {
  width: 50%;
}
.md #logoWhatsapp {
  width: 24px;
  padding-top: 6px;
  padding-left: 10px;
}
.ios #logoWhatsapp {
  width: 24px;
  padding-top: 2px;
  padding-left: 10px;
}
`,
  styleScoped: false
};
    