export default {
  on: {
    pageBeforeIn: function (e, page) {
      var self = this;
      self.$app.panel.close();
      self.$app.dialog.confirm('Deseja encerrar sua sessão?', 'Catálogo Duzani', function () {
        self.$root.encerrarSessao();
      }, function () {
        self.$app.view.main.router.back();
      });
    }
  },
  id: '0df58e2eb8',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n    <div class="page no-swipeback" data-name="loading">\r\n      <div class="page-content bg-color-primary">\r\n      </div>\r\n    </div>\r\n  ';
      return r;
    }(this);
  },
  styleScoped: false
};