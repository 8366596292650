
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    var self = this;
    return { link_catalogo: self.$app.data.link_catalogo };
  },
  on: {
    pageBeforeIn: function (e, page) {
      var self = this;
      self.$app.panel.close();
      self.setAutocompleteCidade();
    }
  },
  methods: {
    setAutocompleteCidade: function () {
      var self = this;
      if (self.autocompletecidademobile === undefined) {
        self.autocompletecidademobile = self.$app.autocomplete.create({
          inputEl: '#autocomplete-cidades-mobile',
          openIn: 'dropdown',
          source: function (query, render) {
            setTimeout(function () {
              if (query.length >= 4) {
                render(self.$app.data.cidades.filter(function (e) {
                  return e.cidade !== null && e.cidade.toLowerCase().indexOf(query.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) >= 0;
                }).map(function (e) {
                  return e.cidade;
                }));
              } else {
                render([]);
              }
            }, 100);
          }
        });
      }
    },
    setLimparDados: function () {
      var self = this;
      self.$setState({ cards: [] });
    },
    setDados: function () {
      var self = this, objetoCidade = objetoCidade = self.$root.cidades.find(function (cidade) {
          return cidade.cidade === document.querySelector('#autocomplete-cidades-mobile').value;
        }), cards = [], card = {};
      if (objetoCidade !== undefined) {
        self.$app.progressbar.show('dourado');
        self.$root.getCidadesTWS(objetoCidade.nome).then(function (dados) {
          if (dados.length > 0) {
            self.$root.getDadosCards(dados.map(function (dado) {
              return dado.ctrl_distribuidor;
            }).join(',')).then(function (dados) {
              self.$app.progressbar.hide();
              if (dados.find(function (e) {
                  return e.ctrldistribuidor === '109';
                }) !== undefined) {
                dados = dados.filter(function (e) {
                  return e.instagram.indexOf('duzani') >= 0;
                });
              }
              dados = dados.filter(function (e) {
                return e['appduzani'] === 't';
              });
              if (dados.length > 1) {
                dados.map(function (dado, i) {
                  if (Object.keys(card).length === 0) {
                    card.regional1 = dado.regional;
                    card.contato1 = dado.contato;
                    card.celular1 = '(' + dado.celular.split(' ')[0] + ') ' + dado.celular.split(' ')[1] + '-' + dado.celular.split(' ')[2];
                    card.instagram1 = dado.instagram !== null ? dado.instagram.split('@')[1] : null;
                    card.telefone_whatsapp1 = '+55' + dado.celular.split(' ')[0] + dado.celular.split(' ')[1] + dado.celular.split(' ')[2];
                    card.ok1 = true;
                  } else if (Object.keys(card).length === 6) {
                    card.regional2 = dado.regional;
                    card.contato2 = dado.contato;
                    card.celular2 = '(' + dado.celular.split(' ')[0] + ') ' + dado.celular.split(' ')[1] + '-' + dado.celular.split(' ')[2];
                    card.instagram2 = dado.instagram !== null ? dado.instagram.split('@')[1] : null;
                    card.telefone_whatsapp2 = '+55' + dado.celular.split(' ')[0] + dado.celular.split(' ')[1] + dado.celular.split(' ')[2];
                    card.ok2 = true;
                    cards.push(card);
                    card = {};
                  }
                  if (i === dados.length - 1 && dados.length % 2 !== 0) {
                    cards.push(card);
                  }
                  return false;
                });
              } else {
                cards.push({
                  regional1: dados[0].regional,
                  contato1: dados[0].contato,
                  celular1: '(' + dados[0].celular.split(' ')[0] + ') ' + dados[0].celular.split(' ')[1] + '-' + dados[0].celular.split(' ')[2],
                  instagram1: dados[0].instagram !== null ? dados[0].instagram.split('@')[1] : null,
                  telefone_whatsapp1: '+55' + dados[0].celular.split(' ')[0] + dados[0].celular.split(' ')[1] + dados[0].celular.split(' ')[2],
                  ok1: true
                });
              }
              self.$setState({ cards: cards });
            });
          } else {
            self.$app.progressbar.hide();
            cards.push({
              regional1: 'Ainda não atuamos diretamente na sua região, mas podemos ter alguém próximo a você!',
              contato1: 'Por favor verifique na lista outra cidade! Ou entre em contato conosco!',
              celular1: '(35) 99990-9598',
              instagram1: 'duzanioficial',
              telefone_whatsapp1: '+5535999909598',
              ok: null
            });
            self.$setState({ cards: cards });
          }
        });
      }
    }
  },
  id: 'fafac13332',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n  <div class="page no-swipeback" data-name="sejauma">\r\n\r\n    <div class="navbar">\r\n      <div class="navbar-inner sliding">\r\n        <div class="left">\r\n          <a href="#" class="link back" data-animate="false">\r\n            <i class="icon icon-back text-color-icone"></i>\r\n            <span class="if-not-md text-color-texto">Voltar</span>\r\n          </a>\r\n        </div>\r\n        <div class="title text-color-texto no-margin-right">Localize um Distribuidor</div>\r\n      </div>\r\n    </div>\r\n\r\n    <div class="page-content bg-color-white">\r\n\r\n      <form class="searchbar" onsubmit="return false;">\r\n        <div class="searchbar-inner">\r\n          <div class="searchbar-input-wrap">\r\n            <input id="autocomplete-cidades-mobile" name="cidade" type="search" placeholder="encontre sua cidade" @change="setDados()" @keypress="setLimparDados()" required validate>\r\n            <i class="searchbar-icon"></i>\r\n            <span class="input-clear-button" @click="setLimparDados()"></span>\r\n          </div>\r\n        </div>\r\n      </form>\r\n\r\n      <div id="recebeDados">\r\n\r\n        ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.cards, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n        <div class="row">            \r\n          \r\n          <div class="col-100 tablet-50 desktop-50">\r\n            <div class="card bg-color-neve" style="border: 1px solid #c1bbbb;border-radius: 22px;">\r\n              <div class="card-content card-content-padding">\r\n                ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.ok1 != null', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                <p class="text-color-icone no-margin century-gothic">Regional de ';
              r += c(ctx_3.regional1, ctx_3);
              r += '</p>\r\n                <p class="text-color-icone no-margin century-gothic" style="font-weight: bold">Bairro: ';
              r += c(ctx_3.contato1, ctx_3);
              r += '</p>\r\n                ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                <p class="text-color-icone no-margin century-gothic">';
              r += c(ctx_3.regional1, ctx_3);
              r += '</p>\r\n                <p class="text-color-icone no-margin century-gothic" style="font-weight: bold">';
              r += c(ctx_3.contato1, ctx_3);
              r += '</p>\r\n                ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                <div class="row margin-top margin-bottom-half">\r\n                  <div class="col-5">\r\n                    <img src="';
          r += c(root.link_catalogo, ctx_2);
          r += 'assets/imgduzani/smartphone.png">\r\n                  </div>\r\n                  <div class="col-90 text-color-icone padding-top-halfinho">Celular: ';
          r += c(ctx_2.celular1, ctx_2);
          r += '</div>\r\n                </div>\r\n                ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.instagram1 != null', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '                      \r\n                  <div class="row margin-bottom">\r\n                    <div class="col-5">\r\n                      <img src="';
              r += c(root.link_catalogo, ctx_3);
              r += 'assets/imgduzani/instagram.png">\r\n                    </div>\r\n                    <div class="col-90">\r\n                      <a href="https://www.instagram.com/';
              r += c(ctx_3.instagram1, ctx_3);
              r += '/" class="text-color-icone external link padding-top-halfinho" target="_system">Instagram: <span style="text-decoration: underline">@';
              r += c(ctx_3.instagram1, ctx_3);
              r += '</span></a>\r\n                    </div>\r\n                  </div>\r\n                ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                  <div class="margin-bottom" style="height: 32px"></div>\r\n                ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                \r\n                <div class="row">\r\n                  <div class="col-10"></div>\r\n                  <div class="col-80 text-align-center">\r\n                    <a href="https://wa.me/';
          r += c(ctx_2.telefone_whatsapp1, ctx_2);
          r += '" class="button button-fill external link bg-color-whatsapp" target="_system"><img src="';
          r += c(root.link_catalogo, ctx_2);
          r += 'assets/imgduzani/logowhatsapp.png" class="icone-whatsapp-localize"> <span class="span-whatsapp-localize">ENVIE SUA MENSAGEM!</span></a>\r\n                  </div>\r\n                  <div class="col-10"></div>\r\n                </div>\r\n\r\n              </div>\r\n            </div>\r\n          </div>\r\n\r\n          <div class="col-100 tablet-50 desktop-50 ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.regional2 == undefined', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' display-none ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '">\r\n            <div class="card bg-color-neve" style="border: 1px solid #c1bbbb;border-radius: 22px;">\r\n              <div class="card-content card-content-padding">\r\n                ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.ok2 != null', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                <p class="text-color-icone no-margin century-gothic">Regional de ';
              r += c(ctx_3.regional2, ctx_3);
              r += '</p>\r\n                <p class="text-color-icone no-margin century-gothic" style="font-weight: bold">Bairro: ';
              r += c(ctx_3.contato2, ctx_3);
              r += '</p>\r\n                ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                <p class="text-color-icone no-margin century-gothic">';
              r += c(ctx_3.regional2, ctx_3);
              r += '</p>\r\n                <p class="text-color-icone no-margin century-gothic" style="font-weight: bold">';
              r += c(ctx_3.contato2, ctx_3);
              r += '</p>\r\n                ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                <div class="row margin-top margin-bottom-half">\r\n                  <div class="col-5">\r\n                      <img src="';
          r += c(root.link_catalogo, ctx_2);
          r += 'assets/imgduzani/smartphone.png">\r\n                  </div>\r\n                  <div class="col-90 text-color-icone padding-top-halfinho">Celular: ';
          r += c(ctx_2.celular2, ctx_2);
          r += '</div>\r\n                </div>\r\n                ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.instagram2 != null', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                  <div class="row margin-bottom">\r\n                    <div class="col-5">\r\n                      <img src="';
              r += c(root.link_catalogo, ctx_3);
              r += 'assets/imgduzani/instagram.png">\r\n                    </div>\r\n                    <div class="col-90">\r\n                      <a href="https://www.instagram.com/';
              r += c(ctx_3.instagram2, ctx_3);
              r += '/" class="text-color-icone external link padding-top-halfinho" target="_system">Instagram: <span style="text-decoration: underline">@';
              r += c(ctx_3.instagram2, ctx_3);
              r += '</span></a>                          \r\n                    </div>\r\n                  </div>\r\n                ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                  <div class="margin-bottom" style="height: 32px"></div>\r\n                ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                \r\n                <div class="row">\r\n                  <div class="col-10"></div>\r\n                  <div class="col-80 text-align-center">\r\n                    <a href="https://wa.me/';
          r += c(ctx_2.telefone_whatsapp2, ctx_2);
          r += '" class="button button-fill external link bg-color-whatsapp" target="_system"><img src="';
          r += c(root.link_catalogo, ctx_2);
          r += 'assets/imgduzani/logowhatsapp.png" class="icone-whatsapp-localize"> <span class="span-whatsapp-localize">ENVIE SUA MENSAGEM!</span></a>\r\n                  </div>\r\n                  <div class="col-10"></div>\r\n                </div>\r\n\r\n              </div>\r\n            </div>\r\n          </div>\r\n\r\n        </div>\r\n        ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n\r\n      </div>\r\n\r\n    </div>\r\n  </div>\r\n';
      return r;
    }(this);
  },
  styleScoped: false
};
    