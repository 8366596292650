export default {
  on: {
    pageBeforeIn: function () {
      var self = this;
      self.$app.ultimatela = self.$app.view.main.history[self.$app.view.main.history.length - 1];
    }
  },
  methods: {
    salvarSenha: function () {
      var self = this, senhaatual = document.querySelector('#text-senhaatual').value, novasenha = document.querySelector('#text-novasenha').value;
      if (senhaatual === self.$app.data.senha && novasenha.length >= 6) {
        self.$root.salvarSenha(novasenha).then(function (dados) {
          if (self.$app) {
            self.$app.toast.create({
              text: 'Senha alterada com sucesso',
              position: 'bottom',
              closeTimeout: 3000
            }).open();
            self.$app.data.senha = novasenha;
            self.$app.view.main.router.back();
          }
        });
      } else if (senhaatual !== self.$app.data.senha) {
        self.$app.toast.create({
          text: 'Senha incorreta',
          position: 'bottom',
          closeTimeout: 2000
        }).open();
      } else if (novasenha.length < 6) {
        self.$app.toast.create({
          text: 'Senha muito pequena',
          position: 'bottom',
          closeTimeout: 2000
        }).open();
      }
    }
  },
  id: 'e25a25b868',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n  <div class="page no-swipeback" data-name="senha">\r\n    \r\n    <div class="navbar">\r\n      <div class="navbar-inner sliding">\r\n        <div class="left">\r\n          <a href="/home/" class="link" data-animate="false">\r\n            <i class="icon icon-back text-color-icone"></i>\r\n            <span class="if-not-md text-color-texto">Voltar</span>\r\n          </a>\r\n        </div>\r\n        <div class="title text-color-texto">Senha</div>\r\n        <div class="right">\r\n          <a href="#" class="button button-fill color-green" @click="salvarSenha()">Salvar</a>\r\n        </div>\r\n      </div>\r\n    </div>\r\n\r\n    <div class="page-content bg-color-white">\r\n\r\n      <div class="block">\r\n        \r\n        <div class="list no-hairlines-md">\r\n          <ul>\r\n            <li class="item-content item-input color-preto">\r\n              <div class="item-inner">\r\n                <div class="item-title item-label">Senha atual</div>\r\n                <div class="item-input-wrap">\r\n                  <input id="text-senhaatual" type="password" placeholder="senha atual">\r\n                </div>\r\n              </div>\r\n            </li>\r\n            \r\n            <li class="item-content item-input color-preto">\r\n              <div class="item-inner">\r\n                <div class="item-title item-label">Nova senha</div>\r\n                <div class="item-input-wrap">\r\n                  <input id="text-novasenha" type="password" placeholder="nova senha">\r\n                </div>\r\n              </div>\r\n            </li>\r\n\r\n          </ul>\r\n        </div>\r\n\r\n      </div>\r\n\r\n    </div>\r\n\r\n  </div>\r\n';
      return r;
    }(this);
  },
  style: `
  .dialog-button {
    color: #282a36 !important;
  }
  .dialog-buttons > span:nth-child(1) {
    display: none
  }
`,
  styleScoped: false
};