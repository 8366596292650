
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    var self = this;
    var ultimatela = '', historico = self.$app.view.main.history;
    for (var i = historico.length - 1; i >= 0; i--) {
      if (ultimatela === '') {
        if (historico[i].indexOf('pesquisar') >= 0) {
          ultimatela = historico[i];
          break;
        } else if (historico[i].indexOf('produtos') >= 0) {
          ultimatela = historico[i];
          break;
        }
      }
    }
    return {
      swipeout: self.$app.data.permiteVisualizarCusto !== undefined ? 'swipeout' : '',
      mostrar_sacola: '',
      loading: true,
      edicao: self.$route.params.edicao,
      telaanterior: ultimatela,
      hack_data: new Date()
    };
  },
  on: {
    pageBeforeIn: function (e, page) {
      var self = this, objeto = self.$app.data.edicaoPagina.find(function (o) {
          return o.edicao == self.$route.params.edicao;
        });
      if (objeto.produto !== undefined) {
        var objeto_produto = objeto.produto;
        self.$setState({
          fotos: objeto_produto.fotos.indexOf('{') >= 0 ? objeto_produto.fotos.parseJSONPG().map(m => {
            m.foto = self.$root.link_agely + m.foto + '#' + self.hack_data;
            return m;
          }) : [{ foto: self.$root.link_catalogo + 'hd/catalogo' + objeto_produto.fotos + '#' + self.hack_data }],
          referencia: objeto.produto.referencia,
          descricao: objeto.produto.descricaobkp
        });
        self.iniSwiperFotos();
      } else {
        self.$app.preloader.show();
        self.$app.methods.getProdutos({
          idpessoa: self.$app.data.idpessoa,
          idempresa: self.$app.data.idempresa,
          idtabelapreco: self.$app.data.idtabelapreco,
          idproduto: self.$route.params.idproduto
        }).then(JSON.parse).then(function (res) {
          self.$app.preloader.hide();
          var objeto_produto = res[0];
          self.$setState({
            fotos: objeto_produto.fotos.indexOf('{') >= 0 ? objeto_produto.fotos.parseJSONPG().map(m => {
              m.foto = self.$root.link_agely + m.foto + '#' + self.hack_data;
              return m;
            }) : [{ foto: self.$root.link_catalogo + 'hd/catalogo' + objeto_produto.fotos + '#' + self.hack_data }],
            referencia: res[0].referencia,
            descricao: res[0].descricao
          });
          self.iniSwiperFotos();
        });
      }
      var promessa = '';
      if (self.$route.params.tipoestoque === 'fabrica') {
        promessa = self.$root.retornaParametros(Number(self.$route.params.edicao), objeto.numpagina);
      } else {
        promessa = self.$root.retornaGradesProprios(Number(self.$route.params.edicao), objeto.numpagina, 'nao');
      }
      promessa.then(function (estoquecatalogoatual) {
        if (self.$app) {
          self.$app.data.estoquecatalogoatual = estoquecatalogoatual.map(function (reg) {
            if (self.$root.getLocalhost() === true) {
              reg.link = 'https://sistemaagely.com.br:8145/' + self.$app.data.versao_fallback + '/' + reg.link;
            } else {
              reg.link = self.$app.data.link_webservice + '/' + reg.link;
            }
            reg.preco = reg.preco;
            reg.precoBR = 'R$ ' + self.$root.formataMoeda(self.$root.setPrecoMargem(reg.preco));
            reg.custoBR = 'R$ ' + self.$root.formataMoeda(reg.preco);
            return reg;
          });
          self.init();
        }
      });
    }
  },
  methods: {
    iniSwiperFotos: function () {
      var self = this;
      if (self.swiperFotos !== undefined) {
        self.swiperFotos.destroy();
      }
      self.swiperFotos = new self.$app.swiper.create('.swiper-fotos-grade', {
        navigation: {
          nextEl: '.swiper-button-next-grade',
          prevEl: '.swiper-button-prev-grade'
        },
        lazy: {
          loadPrevNext: true,
          loadPrevNextAmount: 5
        },
        zoom: { maxRatio: 3 }
      });
    },
    init: function () {
      var self = this;
      var total_sacola = self.$root.getQuantidadeTotalSacola();
      self.$setState({
        mostrar_sacola: total_sacola > 0 ? 'sim' : '',
        total_sacola: total_sacola
      });
      var indice = self.$app.data.edicaoPagina.find(function (e) {
        return e.edicao == self.$route.params.edicao;
      });
      if (self.$app.data.estoquecatalogoatual !== undefined) {
        self.dadosProdutos = self.$app.data.estoquecatalogoatual.filter(function (o) {
          return o.idproduto == self.$route.params.idproduto && o.numpagina === self.$app.data.edicaoPagina.find(function (o) {
            return o.edicao == self.$route.params.edicao;
          }).numpagina;
        });
        self.dadosProdutos.map(m => {
          m.edicao = self.$route.params.edicao;
          return m;
        });
      } else {
        self.dadosProdutos = [];
      }
      self.$setState({
        loading: false,
        linhas: self.dadosProdutos.map(m => {
          m.tamanho = m.descricao.split('| ').find(f => self.$app.data.array_tamanhos.find(f2 => f2.tamanho === f));
          return m;
        }).filter(function (e) {
          return e.idproduto == self.$route.params.idproduto;
        }).map(function (dado) {
          return dado.tamanho;
        }).reduce((a, d) => {
          if (!a.includes(d)) {
            a.push(d);
          }
          return a;
        }, []).filter(function (tamanho) {
          return self.$app.data.array_tamanhos.find(function (objeto) {
            return objeto.tamanho === tamanho;
          }).checado === 'color-blue';
        }).map(function (tamanho) {
          var objeto = self.dadosProdutos.find(function (e) {
            return e.tamanho === tamanho;
          });
          var objeto_Sacola = self.$app.data.array_sacola.find(function (e) {
            return e.idprodutograde === objeto.idprodutograde;
          });
          return {
            edicao: objeto.edicao,
            descricao: objeto.descricao,
            idproduto: objeto.idproduto,
            idprodutosubsecao: objeto.idprodutosubsecao,
            idmarca: objeto.idmarca,
            custoBR: objeto.custoBR,
            idtabelapreco: self.$app.data.idtabelapreco,
            valor: objeto.precoBR,
            preco: objeto.preco,
            tipoEstoque: objeto.tipoEstoque,
            valorsemtab: objeto.precocusto,
            tamanho: tamanho,
            ordem_tamanho: self.$app.data.array_tamanhos.map(function (d) {
              return d.tamanho;
            }).indexOf(tamanho),
            referencia: objeto.ctrl_conjunto,
            idprodutograde: objeto.idprodutograde,
            quantidade: objeto_Sacola === undefined ? 0 : objeto_Sacola.quantidade,
            maximoproprio: objeto.estoque < 10 ? objeto.estoque : 10,
            fotos: self.dadosProdutos.map(function (o) {
              return o.link;
            }).reduce((a, d) => {
              if (!a.includes(d)) {
                a.push(d);
              }
              return a;
            }, [])
          };
        }).sort(self.$root.getOrderBy('ordem_tamanho'))
      });
      if (self.$app.data.resposta !== undefined) {
        self.iniSteppers();
      }
    },
    abrirFotos: function () {
      var self = this;
      self.$app.photoBrowser.create({
        photos: self.linhas[0].fotos,
        lazy: { enabled: true },
        theme: 'dark'
      }).open();
    },
    compartilharProduto: function (idprodutograde) {
      var self = this;
      self.toastCompartilhar = self.$app.toast.create({
        text: 'compartilhando...',
        position: 'bottom',
        closeTimeout: 0
      }).open();
      var objLinha = self.linhas.find(function (linha) {
        return linha.idprodutograde === idprodutograde;
      });
      var objEdicaoPagina = self.$app.data.edicaoPagina.find(function (o) {
        return o.edicao == self.$route.params.edicao;
      });
      var arrayFotos = [];
      arrayFotos.push(self.$app.data.link_catalogo + 'catalogo' + objEdicaoPagina.edicao + '/' + objEdicaoPagina.numpagina + '.jpg');
      if (window.plugins !== undefined) {
        window.plugins.socialsharing.share('Olá gostaria de pedir este produto: ' + objLinha.descricao, null, arrayFotos, null, function (result) {
          self.toastCompartilhar.close();
        }, function (result) {
          self.toastCompartilhar.close();
        });
      }
      setTimeout(function () {
        self.toastCompartilhar.close();
      }, 4000);
    },
    iniSteppers: function () {
      var self = this;
      self.linhas.forEach(function (linha) {
        var el = '.stepper-grade-' + linha.idprodutograde;
        self['stepper-grade-' + linha.idprodutograde] = self.$app.stepper.get(el);
        self.$app.stepper.get(el).setValue(linha.quantidade);
      });
    },
    setValorSacola: function (idprodutograde) {
      var self = this;
      self.$root.setValorSacola(idprodutograde, self['stepper-grade-' + idprodutograde].getValue(), self.linhas);
      var total_sacola = self.$root.getQuantidadeTotalSacola();
      self.$setState({
        mostrar_sacola: total_sacola > 0 ? 'sim' : '',
        total_sacola: total_sacola
      });
    },
    setAvisoSalvarSacola() {
      var self = this;
      if (self.total_sacola >= 5) {
        self.$app.actions.create({
          buttons: [
            {
              text: 'Salve sua sacola agora',
              bold: true,
              onClick: function () {
              }
            },
            {
              text: 'Cancelar',
              color: 'red'
            }
          ]
        }).open();
      }
    }
  },
  id: 'dbce7a5f5a',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n  <div class="page no-swipeback" data-name="grades">\r\n    <div class="navbar">\r\n      <div class="navbar-inner sliding">\r\n        <div class="left">\r\n          <a href="';
      r += c(ctx_1.telaanterior, ctx_1);
      r += '" class="link" data-animate="false">\r\n            <i class="icon icon-back text-color-icone"></i>\r\n            <span class="if-not-md text-color-texto">Voltar</span>\r\n          </a>\r\n        </div>\r\n        <div class="title text-color-texto">Grades</div>\r\n        <div class="right tabbar-labels">\r\n          <a href="/catalogo2/';
      r += c(ctx_1.edicao, ctx_1);
      r += '/" data-animate="false" class="tab-link icon-only">\r\n            <i class="icon material-icons text-color-icone">view_carousel</i>\r\n            <span class="tabbar-label text-color-icone">catálogo</span>\r\n          </a>\r\n          ';
      r += Template7Helpers.js_if.call(ctx_1, '../$root.resposta == \'true\'', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n          <a href="/sacola/" data-animate="false" class="link icon-only">\r\n            ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.mostrar_sacola === \'sim\'', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n              <i class="icon f7-icons if-ios text-color-icone">bag<span class="badge color-blue">';
              r += c(ctx_3.total_sacola, ctx_3);
              r += '</span></i>\r\n              <i class="icon material-icons if-md text-color-icone">shopping_basket<span class="badge color-blue">';
              r += c(ctx_3.total_sacola, ctx_3);
              r += '</span></i>\r\n            ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n              <i class="icon f7-icons if-ios text-color-icone">bag</i>\r\n              <i class="icon material-icons if-md text-color-icone">shopping_basket</i>\r\n            ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n          </a>\r\n          ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <div class="page-content bg-color-white">\r\n      \r\n      <div class="row">\r\n        <div class="col-auto tablet-15"></div>\r\n        <div class="col-auto tablet-70">\r\n\r\n          <div class="list">\r\n            <ul>\r\n              ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.loading, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                <li class="item-content no-chevron skeleton-text skeleton-effect-blink">\r\n                  <div class="item-content width-100">\r\n                    <div class="item-media">\r\n                      <div class="skeleton-block" style="width: 40px; height: 40px; border-radius: 50%"></div>\r\n                    </div>\r\n                    <div class="item-inner">\r\n                      <div class="item-title item-title-valor">333333</div>\r\n                      <div class="item-title item-referencia-tamanho">333333 1 <b>22</b></div>\r\n                    </div>\r\n                  </div>\r\n                </li>\r\n              ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.linhas, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                <li class="item-content no-chevron ';
              r += c(root.swipeout, ctx_3);
              r += '">\r\n                  <div class="item-content swipeout-content width-100">\r\n                    <div class="item-media">\r\n                    ';
              r += Template7Helpers.each.call(ctx_3, ctx_3.fotos, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n                      <img src="';
                  r += c(ctx_4, ctx_4);
                  r += '" class="foto-sistema" />\r\n                    ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\r\n                    </div>\r\n                    \r\n                    <div class="item-inner">\r\n                      ';
              r += Template7Helpers.js_if.call(ctx_3, '../$root.resposta == \'true\'', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n                      <div class="item-title item-title-valor">';
                  r += c(ctx_4.valor, ctx_4);
                  r += '</div>\r\n                      <div class="item-title item-referencia-tamanho">';
                  r += c(ctx_4.referencia, ctx_4);
                  r += ' - <b>';
                  r += c(ctx_4.tamanho, ctx_4);
                  r += '</b></div>\r\n                      ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n                      <div class="item-title">';
                  r += c(ctx_4.referencia, ctx_4);
                  r += ' - <b>';
                  r += c(ctx_4.tamanho, ctx_4);
                  r += '</b></div>\r\n                      ';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\r\n                      <div class="item-after">\r\n                        ';
              r += Template7Helpers.js_if.call(ctx_3, '../$root.resposta == \'true\'', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n                        <div class="stepper-grade-';
                  r += c(ctx_4.idprodutograde, ctx_4);
                  r += ' stepper stepper-fill stepper-init color-green">\r\n                          <div class="stepper-button-minus"></div>\r\n                          <div class="stepper-input-wrap">\r\n                            <input type="text" value="';
                  r += c(ctx_4.quantidade, ctx_4);
                  r += '" min="0" max="';
                  r += c(ctx_4.maximoproprio, ctx_4);
                  r += '" step="1" readonly @change="setValorSacola(';
                  r += c(ctx_4.idprodutograde, ctx_4);
                  r += ')">\r\n                          </div>\r\n                          <div class="stepper-button-plus"></div>\r\n                        </div>\r\n                        ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n                        <button class="button button-fill bg-color-whatsapp" @click="compartilharProduto(';
                  r += c(ctx_4.idprodutograde, ctx_4);
                  r += ');">COMPARTILHAR</button>\r\n                        ';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\r\n\r\n                      </div>\r\n                    </div>\r\n                    \r\n                  </div>\r\n                  ';
              r += Template7Helpers.js_if.call(ctx_3, '@root.swipeout == \'swipeout\'', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n                  <div class="swipeout-actions-right">\r\n                    <a href="#" class="text-color-white bg-color-dourado">';
                  r += c(ctx_4.custoBR, ctx_4);
                  r += '</a>\r\n                  </div>\r\n                  ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\r\n                </li>\r\n                ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n              ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n            </ul>\r\n          </div>\r\n\r\n        </div>\r\n        <div class="col-auto tablet-15"></div>\r\n      </div>  \r\n\r\n    </div>\r\n  </div>\r\n';
      return r;
    }(this);
  },
  style: `
.list {
  margin-top: 0px !important;
}
.descricao {
  padding-bottom: 150px !important;
}
`,
  styleScoped: false
};
    