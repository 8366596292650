
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    var self = this;
    return {
      loading: true,
      telaanterior: self.$app.view.main.history[self.$app.view.main.history.length - 1]
    };
  },
  on: {
    pageBeforeIn: function (e, page) {
      var self = this;
      self.populaLista(true);
      if (self.$app.data.array_sacola.map(function (e) {
          return { ...e };
        }).filter(function (e) {
          return e.quantidade > 0;
        }).length > 0) {
        self.$setState({ loading: false });
        self.iniSheetSacola();
      } else {
        self.$app.sheet.close();
      }
      self.$app.data.referencia_esgotada = '';
    },
    pageBeforeOut: function () {
      var self = this;
      self.$app.toast.close();
      self.$app.sheet.close();
    }
  },
  methods: {
    solicitarPedido: function () {
      var self = this;
      self.toastSolicitar = self.$app.toast.create({
        text: 'compartilhando...',
        position: 'bottom',
        closeTimeout: 0
      }).open();
      if (window.plugins !== undefined) {
        window.plugins.socialsharing.share('Olá gostaria de pedir conjuntos Duzani! Meu pedido é:', null, null, self.$app.data.link_catalogo + 'duzani.html?sacola=' + JSON.stringify(btoa(self.$app.data.array_sacola)), function (result) {
          self.toastSolicitar.close();
        }, function (result) {
          self.toastSolicitar.close();
        });
      }
    },
    finalizarPedido: function () {
      var self = this;
      document.querySelector('.botao-finalizar-pedido').style.display = 'none';
      if (self.$app.data.array_sacola.length > 0) {
        self.toastSalvando = self.$app.toast.create({
          text: 'salvando...',
          position: 'center',
          closeTimeout: 0
        }).open();
        self.$app.data.referencia_esgotada = '';
        self.previne_redirecionar = undefined;
        self.salvarPedidoFabrica();
      } else {
        self.$app.toast.create({
          text: 'Sacola vazia',
          position: 'center',
          closeTimeout: 2000
        }).open();
      }
    },
    salvarPedidoFabrica: function () {
      var self = this, salvou_fabrica = false;
      if (self.$app.data.array_sacola.filter(f => f.tipoEstoque === 'fabrica').length > 0) {
        self.$root.salvarPedidoFabrica().then(JSON.parse).then(function (retorno) {
          if (retorno.indexOf('Erro') >= 0) {
            self.$app.data.referencia_esgotada = retorno.split(' ').filter(function (elemento) {
              return self.$app.data.array_sacola.map(function (item) {
                return Number(item.referencia);
              }).indexOf(Number(elemento)) >= 0;
            })[0];
            self.$root.setPersisteDados();
            var texto_erro = 'Verifique sua sacola! Item sem estoque.';
            if (retorno.indexOf('duplicate key value violates unique constraint') >= 0) {
              texto_erro = 'Sacola já foi salva!';
              setTimeout(() => {
                self.$app.methods.limparSacola();
                self.populaLista(false);
                delete localStorage.chave_unica;
                self.$root.setPersisteDados();
              }, 4000);
            }
            setTimeout(function () {
              self.populaLista(false);
              self.$app.toast.create({
                text: texto_erro,
                position: 'bottom',
                closeTimeout: 3000
              }).open();
            }, 1000);
            setTimeout(() => {
              document.querySelector('.botao-finalizar-pedido').style.display = 'inherit';
              self.toastSalvando.close();
            }, 4000);
          } else {
            salvou_fabrica = true;
            delete localStorage.chave_unica;
          }
        }).finally(function () {
          if (salvou_fabrica === true) {
            self.salvarPedidoProprio();
          }
        });
      } else {
        self.salvarPedidoProprio();
      }
    },
    salvarPedidoProprio: function () {
      var self = this;
      if (self.$app.data.array_sacola.find(function (e) {
          return e.tipoEstoque !== 'fabrica';
        }) !== undefined) {
        self.$root.salvarPedidoProprio().then(JSON.parse).then(function (dados) {
          if (dados.retorno.indexOf('Erro') >= 0) {
            setTimeout(function () {
              self.populaLista(false);
              self.$app.toast.create({
                text: 'Verifique sua sacola! Item Fiorani sem estoque.',
                position: 'bottom',
                closeTimeout: 3000
              }).open();
            }, 1000);
          }
        }).finally(function () {
          self.redirecionaSacola();
        });
      } else {
        self.redirecionaSacola();
      }
    },
    redirecionaSacola: function () {
      var self = this;
      self.toastSalvando.close();
      self.$root.limparSacola();
      self.$app.view.main.router.navigate('/pedidosnovo/', { animate: false });
    },
    limparSacola: function () {
      var self = this;
      self.$app.dialog.confirm('Deseja limpar a sacola?', function () {
        self.$root.limparSacola();
        self.$setState({ loading: true });
        self.$app.sheet.close();
      });
    },
    setVerificaDadosSacola() {
      var self = this, estoquegeral = [], idsprodutos = self.$app.data.array_sacola.unico({ prop: 'idproduto' }).filter(f => f.idproduto > 0).map(m => m.idproduto).join(), numedicoes = self.$app.data.array_sacola.unico({ prop: 'edicao' }).map(m => m.edicao).join();
      estoquegeral = [...self.$app.data.estoquegeral];
      self.$app.data.array_sacola.forEach(function (item, i) {
        var objetoEstoque = estoquegeral.find(f => f.idproduto === item.idproduto && f.idprodutograde === item.idprodutograde && f.idprodutosubsecao === item.idprodutosubsecao);
        if (item.edicao !== undefined) {
          if (Number(item.quantidade) > Number(objetoEstoque.estoque)) {
            self.$root.setValorSacola(item.idprodutograde, 0, self.linhas);
          }
        }
      });
      self.$app.preloader.hide();
      self.populaLista(false);
      return true;
    },
    populaLista: function (verificadados) {
      var self = this;
      self.$root.getEstoqueGeral().then(function (dados) {
        return dados.arrayMobileCatalogoObjeto;
      }).then(function (dados) {
        self.$app.data.estoquegeral = dados.map(m => {
          m.tamanho = m.descricao.split(' ').find(f => self.$app.data.array_tamanhos.find(f2 => f2.tamanho === f));
          return m;
        });
        if (self.$app.data.array_sacola.map(function (e) {
            return { ...e };
          }).filter(function (e) {
            return e.quantidade > 0;
          }).length > 0) {
          self.$setState({
            referencia_esgotada: self.$app.data.referencia_esgotada !== undefined ? self.$app.data.referencia_esgotada : '',
            linhas: self.$app.data.array_sacola.map(function (e) {
              return { ...e };
            }).filter(function (e) {
              return e.quantidade > 0;
            }).sort(self.$root.getOrderBy('referencia', 'ordem_tamanho')),
            totalSacola: 'R$ ' + self.$root.formataMoeda(self.$root.getTotalSacola())
          });
          setTimeout(function () {
            self.iniSteppers();
          }, 0);
          if (verificadados === true) {
            if (localStorage.chave_unica === undefined) {
              self.getChaveUnica();
            }
          }
        } else {
          self.$setState({ linhas: [] });
          self.$app.sheet.close();
        }
      });
    },
    getChaveUnica() {
      var self = this;
      self.$root.getChaveUnica().then(JSON.parse).then(function (res) {
        localStorage.chave_unica = res.arrayMobileCatalogoObjeto[0].chave_unica;
        self.$app.preloader.hide();
      });
    },
    iniSteppers: function () {
      var self = this;
      self.linhas.forEach(function (linha) {
        var el = '.stepper-grade-sacola-' + linha.idprodutograde;
        self['stepper-grade-sacola-' + linha.idprodutograde] = self.$app.stepper.get(el);
        self.$app.stepper.get(el).setValue(linha.quantidade);
      });
    },
    setValorSacola: function (idprodutograde) {
      var self = this;
      self.$root.setValorSacola(idprodutograde, self['stepper-grade-sacola-' + idprodutograde].getValue(), self.linhas);
      self.populaLista(false);
    },
    iniSheetSacola: function () {
      var self = this;
      self.sheetSacola = self.$app.sheet.create({
        el: '.sheet-sacola',
        swipeToClose: false,
        swipeToStep: false,
        backdrop: false
      }).open();
    }
  },
  id: '93f083dc14',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n  <div class="page no-swipeback" data-name="sacola">\r\n    <div class="navbar">\r\n      <div class="navbar-inner sliding">\r\n        <div class="left">\r\n          <a href="';
      r += c(ctx_1.telaanterior, ctx_1);
      r += '" class="link" data-animate="false">\r\n            <i class="icon icon-back text-color-icone"></i>\r\n            <span class="if-not-md text-color-texto">Voltar</span>\r\n          </a>\r\n        </div>\r\n        <div class="title text-color-texto">Sacola</div>\r\n        <div class="right">\r\n          ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.loading, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n          ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n          <a href="#" class="link icon-only" @click="limparSacola()">\r\n            <i class="icon material-icons if-md text-color-icone">delete</i>\r\n            <i class="icon f7-icons if-ios text-color-icone">trash</i>\r\n          </a>\r\n          ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n        </div>\r\n      </div>\r\n    </div>\r\n    \r\n    <div class="page-content bg-color-white">\r\n\r\n      <div class="row">\r\n        <div class="col-auto tablet-15"></div>\r\n        <div class="col-auto tablet-70">\r\n          ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.loading, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n            <p class="padding-left">Você ainda não selecionou nenhum produto! Volte no início e escolha algum produto.</p>\r\n          ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n          <div class="list">\r\n            <ul>\r\n              ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.linhas, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n              ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.referencia == @root.referencia_esgotada', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n              <li class="item-content no-chevron">\r\n                <div class="item-inner">\r\n                  <div class="item-title">Corrija este item para salvar seu pedido</div>\r\n                </div>\r\n              </li>\r\n              ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\r\n              <li class="item-content no-chevron">\r\n                <div class="item-media">\r\n                ';
              r += Template7Helpers.each.call(ctx_3, ctx_3.fotos, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n                  <img src="';
                  r += c(ctx_4, ctx_4);
                  r += '" class="foto-sistema" />\r\n                ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\r\n                </div>\r\n                <div class="item-inner">\r\n                  ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.referencia == @root.referencia_esgotada', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n                    ';
                  r += Template7Helpers.js_if.call(ctx_4, '../$root.resposta == \'true\'', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\r\n                    <div class="item-title item-title-valor">';
                      r += c(ctx_5.valor, ctx_5);
                      r += '</div>\r\n                    <div class="item-title item-referencia-tamanho">';
                      r += c(ctx_5.referencia, ctx_5);
                      r += ' - <b>';
                      r += c(ctx_5.tamanho, ctx_5);
                      r += '</b></div>\r\n                    ';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '\r\n                    <div class="item-title">';
                      r += c(ctx_5.referencia, ctx_5);
                      r += ' - <b>';
                      r += c(ctx_5.tamanho, ctx_5);
                      r += '</b></div>\r\n                    ';
                      return r;
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\r\n\r\n                    <div class="item-after">\r\n                    \r\n                      <div class="stepper-grade-sacola-';
                  r += c(ctx_4.idprodutograde, ctx_4);
                  r += ' stepper stepper-fill stepper-init color-red">\r\n                        <div class="stepper-button-minus"></div>\r\n                        <div class="stepper-input-wrap">\r\n                          <input type="text" value="';
                  r += c(ctx_4.quantidade, ctx_4);
                  r += '" min="0" max="';
                  r += c(ctx_4.maximoproprio, ctx_4);
                  r += '" step="1" readonly @change="setValorSacola(';
                  r += c(ctx_4.idprodutograde, ctx_4);
                  r += ')">\r\n                        </div>\r\n                        <div class="stepper-button-plus"></div>\r\n                      </div>\r\n      \r\n                    </div>\r\n                  ';
                  r += Template7Helpers.js_if.call(ctx_4, '../$root.resposta == \'true\'', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n                    ';
                  r += Template7Helpers.js_if.call(ctx_4, '../$root.resposta == \'true\'', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\r\n                    <div class="item-title item-title-valor">';
                      r += c(ctx_5.valor, ctx_5);
                      r += '</div>\r\n                    <div class="item-title item-referencia-tamanho">';
                      r += c(ctx_5.referencia, ctx_5);
                      r += ' - <b>';
                      r += c(ctx_5.tamanho, ctx_5);
                      r += '</b></div>\r\n                    ';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '\r\n                    <div class="item-title">';
                      r += c(ctx_5.referencia, ctx_5);
                      r += ' - <b>';
                      r += c(ctx_5.tamanho, ctx_5);
                      r += '</b></div>\r\n                    ';
                      return r;
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '\r\n\r\n                    <div class="item-after">\r\n                    \r\n                      <div class="stepper-grade-sacola-';
                  r += c(ctx_4.idprodutograde, ctx_4);
                  r += ' stepper stepper-fill stepper-init color-green">\r\n                        <div class="stepper-button-minus"></div>\r\n                        <div class="stepper-input-wrap">\r\n                          <input type="text" value="';
                  r += c(ctx_4.quantidade, ctx_4);
                  r += '" min="0" max="';
                  r += c(ctx_4.maximoproprio, ctx_4);
                  r += '" step="1" readonly @change="setValorSacola(';
                  r += c(ctx_4.idprodutograde, ctx_4);
                  r += ')">\r\n                        </div>\r\n                        <div class="stepper-button-plus"></div>\r\n                      </div>\r\n      \r\n                    </div>\r\n                  ';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\r\n                </div>\r\n              </li>\r\n              ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n            </ul>\r\n          </div>\r\n          ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n        </div>\r\n        <div class="col-auto tablet-15"></div>\r\n      </div>\r\n\r\n    </div>\r\n\r\n    <div class="sheet-modal sheet-sacola bg-color-gray">\r\n      <div class="sheet-modal-inner">\r\n\r\n        <div class="row">\r\n          <div class="col-auto tablet-15"></div>\r\n          <div class="col-auto tablet-70">\r\n\r\n            <div class="display-flex padding justify-content-space-between align-items-center">\r\n              ';
      r += Template7Helpers.js_if.call(ctx_1, '../$root.resposta == \'true\'', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n              <div style="font-size: 18px"><b>Total:</b></div>\r\n              <div style="font-size: 22px"><b>';
          r += c(ctx_2.totalSacola, ctx_2);
          r += '</b></div>\r\n              ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n              <div style="font-size: 18px">Envie seu pedido para sua consultora!</div>\r\n              ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n            </div>\r\n            <div class="padding-horizontal padding-bottom">\r\n              ';
      r += Template7Helpers.js_if.call(ctx_1, '../$root.resposta == \'true\'', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n              <a href="#" class="button botao-finalizar-pedido button-large button-fill color-green text-color-white" @click="finalizarPedido()">Finalizar Pedido</a>\r\n              ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n              <a href="#" class="button button-large button-fill color-green text-color-white" @click="solicitarPedido()">\r\n                <img src="';
          r += c(ctx_1.$root.link_catalogo, ctx_2);
          r += 'assets/imgduzani/logowhatsapp.png" class="icone-whatsapp-sacola">\r\n                <span class="span-whatsapp-sacola">Enviar Pedido</span>\r\n              </a>\r\n              ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n            </div>\r\n          </div>\r\n          <div class="col-auto tablet-15"></div>\r\n        </div>\r\n\r\n      </div>\r\n    </div>\r\n\r\n  </div>\r\n';
      return r;
    }(this);
  },
  style: `
  .sheet-sacola {
    height: 128px !important;
  }
  .list {
    padding-bottom: 150px !important;
    margin-top: 0px !important;
  }
  .dialog-button {
    color: #282a36 !important;
  }
`,
  styleScoped: false
};
    