
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    var self = this;
    return {};
  },
  on: {
    pageBeforeIn: function () {
      var self = this;
      self.$app.panel.close();
      self.getHistorico();
    }
  },
  methods: {
    onPullToRefresh: function () {
      var self = this;
      self.getHistorico();
    },
    getHistorico: function () {
      var self = this;
      self.$app.preloader.show();
      self.$root.getHistorico().then(JSON.parse).then(function (res) {
        self.$app.preloader.hide();
        document.querySelector('.ptr-refreshing') !== null ? document.querySelector('.ptr-refreshing').classList.remove('ptr-refreshing') : null;
        self.$setState({
          dados: res.map(m => {
            m.status = m.valido === 'false' && m.tipo === 'venda' ? 'disabled' : '';
            m.cor = m.valido === 'false' && m.tipo === 'venda' ? 'bg-color-yellow' : m.tipo === 'venda' ? 'bg-color-green' : 'bg-color-vermelhopastel';
            m.cor_tipo = m.valido === 'false' && m.tipo === 'venda' ? '' : m.tipo === 'venda' ? 'text-color-black' : 'text-color-black';
            m.valorbkp = m.valor;
            m.valor = m.tipo === 'venda' ? self.$root.formataMoeda(m.valor) : self.$root.formataMoeda(m.valor * -1);
            return m;
          })
        });
        var total_venda = Number(self.dados.filter(f => f.tipo === 'venda' && f.valido === 'true').sum({ prop: 'valorbkp' }).toFixed(2));
        var saldo_futuro = Number(self.dados.filter(f => f.tipo === 'venda' && f.valido === 'false').sum({ prop: 'valorbkp' }).toFixed(2));
        var total_desconto = Number(self.dados.filter(f => f.tipo === 'desconto' || f.tipo === 'devolucao').sum({ prop: 'valorbkp' }).toFixed(2));
        var saldo = total_venda + total_desconto;
        self.$setState({
          saldo: self.$root.formataMoeda(saldo),
          saldofuturo: self.$root.formataMoeda(saldo + saldo_futuro)
        });
      });
    }
  },
  id: 'e0c4d20d81',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n  <div class="page no-swipeback" data-name="historico">\r\n\r\n    <div class="navbar" style="height: 80px;">\r\n      <div class="navbar-inner sliding" style="height: 80px;">\r\n        <div class="left">\r\n          <a href="/home/" class="link" data-animate="false">\r\n            <i class="icon icon-back text-color-icone"></i>\r\n            <span class="if-not-md text-color-texto">Voltar</span>\r\n          </a>\r\n        </div>\r\n        <div class="text-color-texto">\r\n\r\n          <div class="data-table">\r\n            <table>\r\n              <thead>\r\n                <tr>\r\n                  <th class="label-cell text-color-texto" style="font-size: 12px;">Saldo Disponível:</th>\r\n                  <td class="numeric-cell text-color-texto" style="font-size: 12px;font-weight: normal;">R$ ';
      r += c(ctx_1.saldo, ctx_1);
      r += '</td>\r\n                </tr>\r\n              </thead>\r\n              <tbody>\r\n                <tr>\r\n                  <th class="label-cell" style="font-size: 12px;">Saldo Disponível + Futuro:</th>\r\n                  <td class="numeric-cell" style="font-size: 12px;font-weight: normal;">R$ ';
      r += c(ctx_1.saldofuturo, ctx_1);
      r += '</td>\r\n                </tr>\r\n            </table>\r\n          </div>\r\n\r\n        </div>\r\n      </div>\r\n    </div>\r\n\r\n    <div class="page-content ptr-content" @ptr:refresh.native="onPullToRefresh">\r\n      <div class="ptr-preloader">\r\n        <div class="preloader"></div>\r\n        <div class="ptr-arrow"></div>\r\n      </div>\r\n      <div class="timeline timeline-sides padding-half">\r\n        \r\n        ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.dados, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n          <div class="no-margin timeline-item timeline-item-right">\r\n            ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.tipo != \'devolucao\'', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n              ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.tipo == \'venda\'', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n              <div class="timeline-item-date margin-right ';
                  r += c(ctx_4.status, ctx_4);
                  r += '">';
                  r += c(ctx_4.data_pedido, ctx_4);
                  r += ' ';
                  r += c(ctx_4.hora_pedido, ctx_4);
                  r += '</div>\r\n              ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n              <div class="timeline-item-date margin-right ';
                  r += c(ctx_4.status, ctx_4);
                  r += '">';
                  r += c(ctx_4.data_validado, ctx_4);
                  r += ' ';
                  r += c(ctx_4.hora_validado, ctx_4);
                  r += '</div>\r\n              ';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\r\n            ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n              <div class="timeline-item-date margin-right ';
              r += c(ctx_3.status, ctx_3);
              r += '">';
              r += c(ctx_3.data_validado, ctx_3);
              r += ' ';
              r += c(ctx_3.hora_validado, ctx_3);
              r += '</div>\r\n            ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n            <div class="timeline-item-divider ';
          r += c(ctx_2.status, ctx_2);
          r += '"></div>\r\n            <div class="timeline-item-content ';
          r += c(ctx_2.status, ctx_2);
          r += '">\r\n              <div class="timeline-item-inner text-color-black ';
          r += c(ctx_2.cor, ctx_2);
          r += '">\r\n                ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.tipo == \'venda\'', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                  <div class="timeline-item-time ';
              r += c(ctx_3.cor_tipo, ctx_3);
              r += '">Desconto da Venda</div>\r\n                ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.tipo == \'devolucao\'', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                  ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.tipo == \'devolucao\'', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n                    <div class="timeline-item-time ';
                  r += c(ctx_4.cor_tipo, ctx_4);
                  r += '">Devolução de Desconto</div>\r\n                  ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n                    <div class="timeline-item-time ';
                  r += c(ctx_4.cor_tipo, ctx_4);
                  r += '">Desconto Utilizado</div>\r\n                  ';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '    \r\n                ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                <div class="timeline-item-title">R$ ';
          r += c(ctx_2.valor, ctx_2);
          r += '</div>\r\n                ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.tipo == \'venda\'', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                  <div class="timeline-item-subtitle margin-top-half">';
              r += c(ctx_3.cliente, ctx_3);
              r += '</div>\r\n                  <div class="timeline-item-text margin-top-half" style="font-size: 12px;">Válido a partir de: ';
              r += c(ctx_3.data_utilizacao, ctx_3);
              r += '</div>\r\n                ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n              </div>\r\n            </div>\r\n          </div>\r\n        ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n\r\n      </div>\r\n\r\n    </div>\r\n\r\n  </div>\r\n';
      return r;
    }(this);
  },
  styleScoped: false
};
    